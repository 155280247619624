import styled from "styled-components";
import { Avatar, Col, Statistic, Checkbox } from "antd";
import { device } from "../lib/breakpoints";
import { Link } from "react-router-dom";

const { Countdown } = Statistic;

export const Container = styled.div`
  max-width: 81.25rem;
  margin: 0 auto;
  padding: 0 1.25rem;
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  max-width: fit-content;
`;

export const ProText = styled.span`
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: bold;
`;

export const Subtitle = styled.div`
  font-size: clamp(1rem, 1.05vw, 1.5rem);
`;

export const SectionHeading = styled.h2`
  font-size: ${(props) => (props.lg ? "4.68rem" : "4.37rem")};
  line-height: ${(props) => (props.lg ? "4.87rem" : "4.62rem")};
  font-weight: 800;
  color: ${(props) =>
    props.primary
      ? "var(--color-primary)"
      : props.secondary
      ? "var(--color-secondary)"
      : "var(--color-tertiary)"};

  @media (max-width: 47.9375rem) {
    font-size: 3.75rem;
    line-height: 4.375rem;
  }

  @media (max-width: 35.9375rem) {
    font-size: 2.375rem;
    line-height: 2.75rem;
  }
`;

export const BodyText = styled.p`
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: var(--color-primary);
  font-weight: 700;
`;

export const Button = styled.a`
  font-family: var(--heading-font);
  background-color: ${(props) =>
    props.primary
      ? "var(--color-primary)"
      : props.secondary
      ? "var(--color-secondary)"
      : "var(--color-tertiary)"};
  padding: 0.7rem 1.5rem;
  display: inline-block;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  color: var(--color-white);
  transition: 0.3s;

  &:hover {
    color: var(--color-white);
    // box-shadow: 8px 8px var(--color-secondary);
    // transition: 0.3s;
  }
`;

export const SocialIconsWrapper = styled.div`
  position: relative;
`;

export const SocialIcons = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  gap: 0.6rem;

  li {
    a {
      font-size: 1.3rem;
      background-color: ${(props) =>
        props.primary ? "var(--color-primary)" : "var(--color-secondary)"};
      color: ${(props) =>
        props.primary ? "var(--color-white)" : "var(--color-primary)"};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.1rem;
      height: 2.1rem;
      border-radius: 2px;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: 4px 4px var(--color-secondary);
        transition: 0.3s;
      }
    }
  }
`;

export const AdminWinner = styled.span`
  text-align: "center";
  margin: "auto";
  display: "inline-flex";

  span {
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    white-space: pre-wrap;
    padding: 0px !important;
    margin: 0px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: white;
    font-size: 10px;
  }
`;

export const SmallAvatar = styled(Avatar)`
  margin: auto;
  margin-right: 6px;
  height: 16px;
  width: 16px;

  .ant-image {
    object-fit: contain !important;
    display: unset !important;
  }
`;

export const SvgObj = styled.object`
  width: 100%;
  height: 100%;
`;

export const AddButton = styled.button`
  color: #fcfcfc;
  font-family: var(--font-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: -0.12px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: var(--color-gry-500);
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  max-width: 200;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 10;
  cursor: pointer;
`;

export const TypesenseSearch = styled.div`
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--color-gray-300);
  border: 0;
  margin-top: 6px;

  .ais-SearchBox {
    width: 100%;
    height: 100%;
  }

  .ais-SearchBox-form {
    width: 100%;
    height: 100%;
  }

  &:hover {
    border: 0;
  }

  &:focus {
    border: 2px solid blue;
  }

  input {
    margin-left: 8px;
    color: white;
    font-family: var(--font-secondary);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
    letter-spacing: -0.15px;
    background: none;
    width: 100%;
    border: 0;

    &:active {
      border: 0;
    }
  }

  button {
    display: none;
  }
`;

export const InputSection = styled.div`
  margin: 24px 0px 36px 0px;

  .ant-switch {
    width: 36px;

    &:hover {
      background: #65686d;
    }
  }

  .ant-switch-checked {
    background: var(--color-pink-normal) !important;
  }
`;

export const Section = styled.div`
  border-radius: 8px;
  background: var(--color-gray-500);
  padding: 18px 12px;
`;

export const ErrorText = styled.span`
  color: var(--color-red);
`;

export const NotificationCloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

export const Spacer = styled.div`
  margin-top: ${(props) => (props.$marginTop ? props.$marginTop : "0px")};
`;

const SpinnerInternal = styled.img`
  width: ${(props) => (props.width ? props.width : "40px")};
  height: ${(props) => (props.height ? props.height : "40px")};
  animation: rotation 2s infinite linear;
`;
export const Spinner = ({ variant = "lavender", ...props }) => {
  const spinnerSvg =
    variant === "lavender"
      ? "/icons/loading-spheres-lavender.svg"
      : "/icons/loading-spheres.svg";

  return <SpinnerInternal src={spinnerSvg} {...props} />;
};

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveMark = styled.span`
  color: #9c86e1;
  font-family: "Symbola";
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : "0px")};
  margin-right: ${(props) => (props.$marginRight ? props.$marginRight : "0px")};
`;
export const ActiveStoryworldMark = ({ ...props }) => {
  return <ActiveMark {...props}>🕮</ActiveMark>;
};

export const ProfileBugOverlayContainer = styled.div`
  position: absolute;
  display: flex;
  z-index: 2;
  transform: translate(95%, 66%);

  @media (max-width: 450px) {
    transform: translate(110%, 100%) !important;
  }

  @media (max-width: 420px) {
    transform: translate(97.5%, 100%) !important;
  }

  @media (max-width: 410px) {
    transform: translate(95%, 100%) !important;
  }

  @media (max-width: 400px) {
    transform: translate(90%, 100%) !important;
  }

  @media (max-width: 390px) {
    transform: translate(87.5%, 100%) !important;
  }

  @media (max-width: 380px) {
    transform: translate(85%, 100%) !important;
  }
`;

export const LiveCountdown = styled(Countdown)`
  display: inline-flex;
  color: var(--color-pink);
  font-size: 10px;
  line-height: 24px;
  margin-top: 4px;

  span {
    color: #f5a2ff;
    font-size: 10px;
    line-height: 24px;
  }
`;

export const EmptyCountdown = styled(Countdown)`
  display: inline-flex;

  span {
    font-size: 11px;
    color: #ffb64c;
  }
`;

export const LikeButtonOverlay = styled.div`
  height: auto;
  position: absolute;
  top: 2%;
  right: 5%;
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  color: #ffffff;
`;

export const UploadHintNoHide = styled.p`
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 24px !important;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--inputFieldText);
  line-height: 17px !important;
  margin-bottom: 0px;
`;

export const TapToUploadText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-gray-400);
`;

export const InputHeader = styled.label`
  color: var(--color-gray-400);
  font-family: var(--font-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.2px;
  margin-bottom: 0.5rem;
  text-align: left;
`;

export const InputArea = styled.textarea`
  font-size: 14px;
  width: 100%;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-300);
  padding: 14px;
  border-radius: 12px;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  color: white;
  min-height: 100px;
  resize: none;

  &:focus {
    border-color: #978ad7;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  &:disabled {
    background: var(--color-gray-450);
    pointer-events: none;
    user-select: none;
    color: #65676d;
  }
`;

export const StyledForm = styled.form`
  position: relative;
  z-index: 1;
`;

export const StyledInput = styled.input`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  padding-left: 18px;
  border-radius: ${(props) =>
    props.$borderRadius ? props.$borderRadius : "8px"};
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: auto;
  background: var(--color-gray-50);
  color: black;

  &:focus {
    border-color: ${(props) =>
      !props.$hideFocusBorder ? "transparent" : "#978ad7"};
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }
`;

export const FormError = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-block: 8px;
  color: var(--color-red);
`;

export const Highlight = styled.span`
  color: var(--color-highlight);
  font-family: var(--font-secondary);
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 19.2px */
  letter-spacing: 0.2px;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  border-color: var(--color-gray-200) !important;

  .ant-checkbox-disabled {
    border-color: var(--color-gray-200) !important;
  }

  .ant-checkbox:hover {
    height: 28px;
    width: 28px;
    border-radius: 8px !important;
    border-color: var(--color-gray-200) !important;
  }

  .ant-checkbox-inner {
    height: 28px;
    width: 28px;
    border-radius: 8px !important;
    border-color: var(--color-gray-200) !important;
  }

  .ant-checkbox-checked {
    background: var(--nextPreviewButton) !important;
    border-color: var(--nextPreviewButton) !important;
    border-radius: 8px !important;

    .ant-checkbox-inner {
      background: transparent;
      border-color: var(--nextPreviewButton) !important;
    }
  }

  .ant-checkbox-inner {
    background: transparent;
  }

  .ant-checkbox::after {
    background: var(--nextPreviewButton) !important;
    border-color: var(--nextPreviewButton) !important;
    border-radius: 8px !important;
  }

  .ant-checkbox-inner::after {
    z-index: 9;
    width: 6px;
    height: 12px;
    margin-left: 3px;
    margin-top: -1px;
  }

  &:hover {
    border-color: var(--color-gray-200) !important;
  }

  .ant-checkbox-wrapper:hover {
    border-color: var(--color-gray-200) !important;
  }
`;

export const Line = styled.hr`
  border: 1px solid var(--border-light);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 2px;
`;

export const PaddedContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

export const PromoOrder = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 0px;
  background: var(--color-gray-500);
  color: var(--color-cream);
  font-family: var(--font-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.12px;
  top: 0;
  left: 0;
`;

export const RightAlignedCol = styled(Col)`
  text-align: right;
`;

export const PreviewMediaButton = styled.button`
  color: white;
  background: var(--color-black);
  height: auto;
  padding: 14px 24px;
  gap: 8px;
  border-radius: 12px;
  border: 0;
  display: inline-flex;
  cursor: pointer;
  color: #fff;
  font-family: var(--font-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: -0.12px;
  opacity: ${(props) => props.disabled && "0.25"};

  &:hover {
    background: var(--color-black);
    color: white !important;
  }

  &:focus {
    background: var(--color-black);
    color: white !important;
  }
`;

export const QuestDisplayTitle = styled.h2`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
`;

export const QuestDisplayText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--inputFieldText);
  padding: 0px !important;
  margin: 0px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QuestDisplayTimeText = styled.p`
  font: unset !important;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 24px;
  letter-spacing: 0em;
  /* margin: 12px 0px 18px 0px; */
  color: white;
  white-space: pre-wrap;
  padding: 0px !important;
  margin: auto;

  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  opacity: 1;
  background-image: linear-gradient(
      30deg,
      #f7f7f7 12%,
      transparent 12.5%,
      transparent 87%,
      #f7f7f7 87.5%,
      #f7f7f7
    ),
    linear-gradient(
      150deg,
      #f7f7f7 12%,
      transparent 12.5%,
      transparent 87%,
      #f7f7f7 87.5%,
      #f7f7f7
    ),
    linear-gradient(
      30deg,
      #f7f7f7 12%,
      transparent 12.5%,
      transparent 87%,
      #f7f7f7 87.5%,
      #f7f7f7
    ),
    linear-gradient(
      150deg,
      #f7f7f7 12%,
      transparent 12.5%,
      transparent 87%,
      #f7f7f7 87.5%,
      #f7f7f7
    ),
    linear-gradient(
      60deg,
      #f7f7f777 25%,
      transparent 25.5%,
      transparent 75%,
      #f7f7f777 75%,
      #f7f7f777
    ),
    linear-gradient(
      60deg,
      #f7f7f777 25%,
      transparent 25.5%,
      transparent 75%,
      #f7f7f777 75%,
      #f7f7f777
    );
  background-size: 40px 70px;
  background-position:
    0 0,
    0 0,
    20px 35px,
    20px 35px,
    0 0,
    20px 35px;
`;

export const MainHeader = styled.div`
  height: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  align-content: center;
  text-transform: capitalize;

  @media ${device.mobile} {
    font-size: 22px;
  }
`;
