import { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import Spinner from "./Spinner";
import { adminList } from "../helpers/constants";
import { isOnMobile } from "../helpers/general";
import { firebaseAuth } from "../helpers/authentication";
import { DIALOG_NAME } from "../helpers/useDialogSearchParam";

// This component is for the pages that should redirect if the user DOES have an account (login, signup)
const AuthenticationRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [initialUserLookupCompleted, setInitialUserLookupCompleted] = useState(
    false
  );
  const { user, loading, userInfo, isUserInfoLoading } = useContext(
    AuthContext
  );

  useEffect(() => {
    const isUserAdmin = adminList.includes(user?.email);
    const onMobile = isOnMobile();

    // We don't want to redirect from this component once the user has completed login/registration
    if (user && userInfo && initialUserLookupCompleted === false) {
      // ? Can these two conditions be simplified?
      if (!isUserAdmin && !onMobile) {
        // Sign out if user is not an admin and visiting on desktop
        firebaseAuth
          .signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.error("Sign out Error:", error);
          });
      } else {
        navigate("/", { replace: true });
      }
    }

    if (!loading && !isUserInfoLoading) {
      setInitialUserLookupCompleted(true);
    }
  }, [
    initialUserLookupCompleted,
    isUserInfoLoading,
    loading,
    navigate,
    user,
    userInfo,
  ]);

  if (loading || isUserInfoLoading) {
    return <Spinner style={{ margin: "auto", marginTop: "24px" }} />;
  }

  // Users who have created an account but haven't created a profile yet are forced to this page
  if (
    location.pathname !== "/signup" &&
    !searchParams.get(DIALOG_NAME.signup) &&
    user &&
    !userInfo
  ) {
    return <Navigate to="/signup" replace />;
  }

  return children ? children : <Outlet />;
};

export default AuthenticationRoute;
