import React, { useRef, useState, useEffect } from "react";
import { XCircle as StartOverIcon } from "lucide-react";
import styled from "styled-components";
import Canvas from "../components/Canvas";
import Dropzone from "../components/Dropzone";
import Button from "../components/Button";
import { Spacer } from "../styles/CommonElements";
import predictionModel from "../lib/firebase/predictionModel";
import { APP_NAME } from "../helpers/constants";
import { Row, Col, message, Select } from "antd";
import { readAsDataURL } from "../helpers/general";
import Spinner from "../components/Spinner";
import Hidden from "./../components/Hidden";
import RecentPredictions from "./RecentPredictions";
import { useQueryClient } from "@tanstack/react-query";
import { predictionKeys } from "../lib/queryKeys";
import PredictionResultModal from "../components/general/PredictionResultModal";
import patientModel from "../lib/firebase/patientModel";
import { useQuery } from "@tanstack/react-query";
import { patientKeys } from "../lib/queryKeys";
import { AuthContext } from "../context/AuthProvider";

// Add custom styling for Ant Design messages
import "antd/dist/reset.css";

// CSS to override Ant Design message text color
import { createGlobalStyle } from "styled-components";

const GlobalMessageStyle = createGlobalStyle`
  .ant-message-notice-content {
    color: black !important;
  }
  
  .ant-message-custom-content {
    color: black !important;
  }
  
  .ant-message-success .ant-message-custom-content,
  .ant-message-error .ant-message-custom-content,
  .ant-message-warning .ant-message-custom-content,
  .ant-message-info .ant-message-custom-content {
    color: black !important;
  }

  .anticon + span {
    color: black !important;
  }
`;

const NOTIFICATION_DURATION = 10;
const ACTION_WORD = "Create BodyMagic";

const CAMERA_ICON = "/icons/paint/camera.svg";
const DRAW_ICON = "/icons/paint/draw.svg";
const HEAD_ICON = "/icons/paint/head.svg";
const CAMERA_WITH_PLUS_ICON = "/icons/paint/camera-with-plus.svg";

export default function Paint() {
  const queryClient = useQueryClient();
  const fileInputRef = useRef();
  const { userInfo } = React.useContext(AuthContext);

  const [messageApi, contextHolder] = message.useMessage();

  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState(null);
  const [maskImage, setMaskImage] = useState(null);
  const [userUploadedImage, setUserUploadedImage] = useState(null);
  const [predicting, setPredicting] = useState(false);
  const [timeToCreate, setTimeToCreate] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const canvas = useRef();

  // Fetch patients
  const { data: patients, isLoading: patientsLoading } = useQuery({
    queryKey: patientKeys.user(userInfo?.id),
    queryFn: () => {
      if (!userInfo?.id) return [];
      return patientModel.getMany(
        ["creatorId", "==", userInfo.id],
        ["sort", "name", "asc"]
      );
    },
    enabled: !!userInfo?.id,
    placeholderData: [],
  });

  const startOver = async (e) => {
    if (e) e.preventDefault();
    setPredictions([]);
    setError(null);
    setMaskImage(null);
    setUserUploadedImage(null);
    setPredicting(false);

    // Reset the file input to ensure it can be used again with the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  /*
  const removeMask = async (e) => {
    if (e) e.preventDefault();
    setPredictions([]);
    setError(null);
    setMaskImage(null);
    setPredicting(false);
  };
  */

  // Handle Submit -- Replicate
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPredicting(true);
    setPredictions([]);

    messageApi.open({
      type: "success",
      content: `Getting ${APP_NAME} Prediction...`,
      duration: NOTIFICATION_DURATION * 2,
    });

    const prevPrediction = predictions[predictions.length - 1];
    const prevPredictionOutput = prevPrediction?.output
      ? Array.isArray(prevPrediction.output)
        ? prevPrediction.output[prevPrediction.output.length - 1]
        : prevPrediction.output
      : null;

    const prediction = await predictionModel.createReplicate({
      image: userUploadedImage
        ? await readAsDataURL(userUploadedImage)
        : // only use previous prediction as init image if there's a mask
        maskImage
        ? prevPredictionOutput
        : null,
      maskImage,
      patientId: selectedPatient.id,
      patientName: selectedPatient.name,
    });

    if (prediction.status === "succeeded") {
      setPredictions([prediction]);
      const creationSeconds =
        (new Date(prediction.completed_at) - new Date(prediction.created_at)) /
        1000;
      setTimeToCreate(creationSeconds);
      setModalOpen(true);
      messageApi.open({
        type: "success",
        content: `${APP_NAME} result for ${
          selectedPatient.name
        } created in ${creationSeconds.toFixed(2)} seconds`,
        duration: NOTIFICATION_DURATION,
      });

      // Invalidate all prediction queries and specifically for this patient
      queryClient.invalidateQueries({
        queryKey: predictionKeys.all,
      });
      queryClient.invalidateQueries({
        queryKey: [...predictionKeys.user(userInfo?.id), selectedPatient.id],
      });
    }

    setPredicting(false);
  };

  /*
  // Handle Submit -- Graydient
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("-----------");
    console.log("Started Generation");

    // Upload user supplied image
    const image = await uploadImage("before", userUploadedImage);

    // Upload mask image
    const maskPng = addBackgroundToPNG(maskImage, true);
    const mask = await uploadImage("masks", maskPng);

    const predictionId = await predictionModel.createGraydient({
      image,
      maskImage: mask,
    });

    console.log("Returned a prediction ID");
    console.log(predictionId);

    /*
    const prediction = await retrieveGraydientImagesFromFirebase(predictionId);
    console.log(prediction);
    setPredictions(prediction);
    */
  /*
  };
  */

  const handlePatientSelect = (patientId) => {
    const patient = patients.find((p) => p.id === patientId);
    setSelectedPatient(patient);
    // You could potentially load patient information or previous predictions here
    messageApi.open({
      type: "info",
      content: `Selected patient: ${patient?.name}`,
      duration: 3,
    });
  };

  const handleFileUpload = (e) => {
    // Check if a patient is selected first
    if (!selectedPatient) {
      messageApi.open({
        type: "warning",
        content: "Please select a patient before uploading an image",
        duration: 5,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    const file = e.target.files?.[0];
    if (!file) return;

    try {
      // Create a clean URL for the file
      const imageUrl = URL.createObjectURL(file);

      // Check if it's a valid image
      const img = new Image();
      img.onload = () => {
        // Valid image - set it
        setUserUploadedImage(file);
        messageApi.open({
          type: "success",
          content: `Image uploaded successfully for ${selectedPatient.name}!`,
          duration: 3,
        });
      };

      img.onerror = () => {
        // Invalid image
        setUserUploadedImage(null);
        messageApi.open({
          type: "error",
          content:
            "Could not load the selected image. Please try another file.",
          duration: 5,
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };

      img.src = imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      messageApi.open({
        type: "error",
        content: "Error uploading image. Please try again.",
        duration: 5,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const clearSelectedPatient = () => {
    setSelectedPatient(null);
    setUserUploadedImage(null);
    setMaskImage(null);
    setPredictions([]);

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    messageApi.open({
      type: "info",
      content: "Patient selection cleared",
      duration: 3,
    });
  };

  return (
    <>
      {contextHolder}
      <GlobalMessageStyle />

      <Container>
        {/*}
        <HeaderContainer>
          <MainHeader>Get {APP_NAME} Prediction</MainHeader>
        </HeaderContainer>
        */}

        <Hidden desktop tablet>
          <Spacer $marginTop="2rem" />
          <Subheader>
            To get a {APP_NAME} prediction, please access this page on desktop
            or tablet devices
          </Subheader>
        </Hidden>
        <Hidden mobile>
          <InnerContainer>
            <FlexLayout>
              <SidePanel>
                <PatientSelectorContainer $hasPatient={!!selectedPatient}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <PatientSelectorLabel>Select Patient:</PatientSelectorLabel>
                    {selectedPatient && (
                      <ClearButton onClick={clearSelectedPatient}>
                        Clear
                      </ClearButton>
                    )}
                  </div>
                  <PatientSelector
                    loading={patientsLoading}
                    placeholder="Choose a patient"
                    onChange={handlePatientSelect}
                    value={selectedPatient?.id}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {patients?.map((patient) => (
                      <PatientOption key={patient.id} value={patient.id}>
                        {patient.name}
                      </PatientOption>
                    ))}
                  </PatientSelector>
                  {selectedPatient && (
                    <PatientInfo>
                      <PatientDetail>
                        <strong>Email:</strong> {selectedPatient.email}
                      </PatientDetail>
                      <PatientDetail>
                        <strong>Phone:</strong> {selectedPatient.phone || "N/A"}
                      </PatientDetail>
                      <PatientDetail>
                        <strong>Status:</strong>
                        <PatientStatus
                          status={selectedPatient.status || "inactive"}
                        >
                          {selectedPatient.status || "Inactive"}
                        </PatientStatus>
                      </PatientDetail>
                    </PatientInfo>
                  )}
                  {!selectedPatient && (
                    <PatientSelectionRequired>
                      Please select a patient first to continue
                    </PatientSelectionRequired>
                  )}
                </PatientSelectorContainer>

                {selectedPatient && (
                  <InstructionsPanel>
                    <InstructionItem>
                      <IconContainer>
                        <Icon src={CAMERA_ICON} alt="" />
                      </IconContainer>
                      <InstructionText>Upload image of patient</InstructionText>
                    </InstructionItem>

                    <InstructionItem>
                      <IconContainer>
                        <Icon src={DRAW_ICON} alt="" />
                      </IconContainer>
                      <InstructionText>
                        Use your mouse to fill the area
                      </InstructionText>
                    </InstructionItem>

                    <InstructionItem>
                      <IconContainer>
                        <Icon src={HEAD_ICON} alt="" />
                      </IconContainer>
                      <InstructionText>
                        Click {ACTION_WORD} to get a prediction
                      </InstructionText>
                    </InstructionItem>

                    <Spacer $marginTop="1rem" />

                    <FileInputContainer>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <UploadButton
                        variant="primary"
                        rounded="slight"
                        onClick={() => fileInputRef.current.click()}
                        disabled={predicting}
                      >
                        <IconContainer>
                          <StyledIcon src={CAMERA_WITH_PLUS_ICON} alt="" />
                        </IconContainer>
                        <span>Upload</span>
                      </UploadButton>
                    </FileInputContainer>
                  </InstructionsPanel>
                )}
              </SidePanel>

              <MainContent>
                <main className="container">
                  <Row>
                    <Col span={24}>
                      <ImageContainer className="mx-auto relative">
                        <Dropzone
                          onImageDropped={(file) => {
                            if (!selectedPatient) {
                              messageApi.open({
                                type: "warning",
                                content:
                                  "Please select a patient before uploading an image",
                                duration: 5,
                              });
                              return;
                            }
                            setUserUploadedImage(file);
                          }}
                          predictions={predictions}
                          userUploadedImage={userUploadedImage}
                          className="border-hairline max-w-[min(1024px,100vw-40px)]"
                        />
                        <ImageHolder
                          className="bg-gray-50 relative w-full flex items-stretch"
                          style={{
                            maxHeight: "min(768px, 100vw - 40px)",
                            aspectRatio: "4 / 3",
                          }}
                        >
                          {userUploadedImage && (
                            <CloseButton onClick={startOver}>
                              <StartOver src="/icons/x-icon-gray.svg" alt="" />
                            </CloseButton>
                          )}
                          <Canvas
                            predictions={predictions}
                            userUploadedImage={userUploadedImage}
                            onDraw={setMaskImage}
                            canvas={canvas}
                          />
                        </ImageHolder>

                        <Spacer $marginTop="1rem" />

                        <StyledButton
                          variant="primary"
                          rounded="slight"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          disabled={predicting || !maskImage}
                        >
                          {predicting ? <Spinner /> : `${ACTION_WORD}`}
                        </StyledButton>
                      </ImageContainer>

                      <div className="max-w-[min(1024px,100vw-40px)] mx-auto">
                        {error && (
                          <div className="text-red-700 bg-red-50 p-3 rounded-md mb-5">
                            {error}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </main>
              </MainContent>
            </FlexLayout>
          </InnerContainer>

          <Spacer $marginTop="4rem" />

          {selectedPatient && (
            <RecentPredictions
              patientName={selectedPatient.name}
              patientId={selectedPatient.id}
            />
          )}
          <Spacer $marginTop="5rem" />
          <PredictionResultModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            userUploadedImage={userUploadedImage}
            prediction={predictions[0]?.output}
            timeToCreate={timeToCreate}
            selectedPatient={selectedPatient}
          />
        </Hidden>
      </Container>
    </>
  );
}

const Container = styled.div``;

const CloseButton = styled.button`
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1005;
  background: transparent;
  border: 0;
  cursor: pointer;
  background: #383838;
  border-radius: 0 0px 0 10px;
  height: 36px;
`;

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Icon = styled.img`
  height: 24px;
  width: 100%;
  filter: brightness(0);
`;

const IconContainer = styled.div`
  margin-right: 4px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const ImageContainer = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 14px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: white;
`;

const ImageHolder = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.25);
  justify-content: center;
  object-fit: contain;
  position: relative;
`;

const InnerContainer = styled.div`
  background: rgba(0, 0, 0, 0.05);
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
`;

const InstructionItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.15rem 0;
  font-weight: 600;
`;

const InstructionsPanel = styled.div`
  padding: 0 1rem;
  align-self: flex-start;
  width: 100%;
`;

const InstructionText = styled.div`
  font-size: 16px;
  line-height: 1.4;
  padding-top: 8px;
  text-align: left;
`;

const MainContent = styled.div`
  flex: 1;
  min-width: 0;
`;

const PatientDetail = styled.div`
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PatientInfo = styled.div`
  background: rgba(0, 123, 255, 0.05);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 0.5rem;
`;

const PatientOption = styled(Select.Option)`
  padding: 8px;
`;

const PatientSelector = styled(Select)`
  width: 100%;
  margin-bottom: 1rem;

  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    background: white !important;
    height: auto !important;
    min-height: 42px !important;
    display: flex;
    align-items: center;
    color: black !important;
  }

  .ant-select-selection-item {
    color: black !important;
    font-weight: 500;
  }

  .ant-select-selection-search-input {
    height: 38px !important;
    color: black !important;
  }

  .ant-select-selection-placeholder {
    color: #666;
    opacity: 0.8;
  }

  .ant-select-clear {
    background: white;
  }

  .ant-select-dropdown {
    color: black !important;
  }

  .ant-select-arrow {
    font-size: 16px !important;
    right: 11px !important;
    top: 50% !important;
    margin-top: -8px !important;
    color: rgba(0, 0, 0, 0.65) !important;
    transform: translateY(0) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 16px !important;
    padding-top: 0.7rem !important;
  }
`;

const PatientSelectorContainer = styled.div`
  padding: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border: 2px solid
    ${(props) => (props.$hasPatient ? "rgba(0, 123, 255, 0.5)" : "transparent")};
  transition: all 0.2s ease;

  ${(props) =>
    props.$hasPatient &&
    `
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.15);
  `}
`;

const PatientSelectorLabel = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 16px;
`;

const PatientStatus = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;

  ${({ status }) => {
    switch (status) {
      case "active":
        return "background: #e6f4ea; color: #1e7e34;";
      case "pending":
        return "background: #fff3cd; color: #856404;";
      default:
        return "background: #f8f9fa; color: #6c757d;";
    }
  }}
`;

const SidePanel = styled.div`
  flex: 0 0 420px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StartOver = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 14px;
  width: 14px;
  filter: brightness(0) invert(1);
`;

const StyledButton = styled(Button)`
  svg {
    width: 24px !important;
    height: auto !important;
  }
`;

const StyledIcon = styled(Icon)`
  filter: invert(1);
`;

const Subheader = styled.div`
  width: 70%;
  max-width: 900px;
  font-size: 16px;
  margin: auto;
`;

const UploadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;

  svg {
    margin-right: 4px;
  }
`;

const PatientSelectionRequired = styled.div`
  background: rgba(255, 193, 7, 0.1);
  border: 1px solid rgba(255, 193, 7, 0.3);
  color: #856404;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 13px;
  cursor: pointer;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    color: #333;
  }
`;
