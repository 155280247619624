import { useContext } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { Spinner } from "../styles/CommonElements";
import { adminList } from "../helpers/constants";
import { device, useBreakpoints } from "../lib/breakpoints";
import { DIALOG_NAME } from "../helpers/useDialogSearchParam";

const ProtectedRoute = ({
  redirectPath = "/",
  requireAccount = false,
  requireAdmin = false,
  supportedBreakpoints = [device.mobile, device.tablet, device.desktop],
  children,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { user, loading, userInfo, isUserInfoLoading } = useContext(
    AuthContext
  );
  const { isMobile, isTablet } = useBreakpoints();

  const currentBreakpoint = isMobile
    ? device.mobile
    : isTablet
    ? device.tablet
    : device.desktop;

  const isUserAdmin = adminList.includes(user?.email);

  if (loading || isUserInfoLoading) {
    return <Spinner style={{ margin: "auto", marginTop: "24px" }} />;
  }

  // Users who have created an account but haven't created a profile yet are forced to the signup page, unless they have the signup dialog open
  if (
    location.pathname !== "/signup" &&
    searchParams.get(DIALOG_NAME.signup) === null &&
    user &&
    !userInfo
  ) {
    return <Navigate to="/signup" replace />;
  }

  if (requireAccount || requireAdmin) {
    if (!user) {
      const to = isMobile ? "/login" : `/?${DIALOG_NAME.login}=`;
      return <Navigate to={to} replace />;
    } else if (requireAdmin && !isUserAdmin) {
      return <Navigate to="/" replace />;
    }
  }

  if (!supportedBreakpoints.includes(currentBreakpoint)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
