import { getAuth } from "firebase/auth";
import { patientTable } from "../../helpers/tables";
import { queryOne, queryMany } from "./all";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../helpers/firebase";

const table = patientTable;

const patientModel = {
  create: async (props) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const ref = await addDoc(collection(db, table), {
      ...props,
    });

    const doc = await queryOne({ table, id: ref.id });
    if (!doc) throw new Error("Patient not created");
    return doc;
  },
  update: async (id, data) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    const docRef = doc(db, table, id);
    await updateDoc(docRef, {
      ...data,
      updatedAt: new Date(),
    });

    const updatedDoc = await queryOne({ table, id });
    if (!updatedDoc) throw new Error("Patient not found after update");
    return updatedDoc;
  },
  delete: async (id) => {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not logged in");

    await deleteDoc(doc(getFirestore(), table, id));
  },
  getMany: async (...args) => {
    const data = await queryMany({
      table,
      conditions: [...args],
    });
    return data;
  },
  getOneById: async (id) => {
    const data = await queryOne({ table, id });
    return data;
  },
};

export default patientModel;
