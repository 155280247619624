import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useBreakpoints } from "../lib/breakpoints";
import { HeaderContainer, MainHeader, Spacer } from "../styles/CommonElements";
import HomeCTA from "../pages/home/HomeCTA";
import ContactPage from "../pages/home/ContactPage";
import Hidden from "../components/Hidden";

export default function Contact() {
  const location = useLocation();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <Container>
      {/*
      <HeaderContainer>
        <MainHeader>Contact us to get started</MainHeader>
      </HeaderContainer>
      {!isMobile && <Spacer $marginTop="1.5rem" />}
      */}
      <ContactPage />
      <Hidden mobile>
        <Spacer $marginTop="3rem" />
      </Hidden>
      <HomeCTA />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
