import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../helpers/authentication";
import Button from "../../Button";
import CustomModal from "../../CustomModal";
import styled from "styled-components";
import SettingsItem from "./SettingsItem";
import { Spacer } from "../../../styles/CommonElements";
import { useState } from "react";
import ContactSupport from "../ContactSupport";
import FeedSettingsList from "../../feed/FeedSettingsList";
import { ChangePassword } from "../../../pages";
import ManageAccount from "./ManageAccount";
import { Row, Col, notification } from "antd";
import DeleteUserModalContent from "../DeleteUserModalContent";

const LOGOUT_ICON = "/icons/general/logout.svg";

export const OPTION_TYPES = {
  ACCOUNT: "account",
  ACTIVITY: "activity",
  DELETE: "delete",
  EMAIL: "email",
  HELP: "help",
  FEEDBACK: "feedback",
  PASSWORD: "password",
};

const SettingsModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const [type, setType] = useState(null);

  const ACCOUNT_ACTIONS = [
    {
      title: "Manage Account",
      icon: "/icons/search/user.svg",
      onClick: () => setType(OPTION_TYPES.ACCOUNT),
    },
    /*
    {
      title: "Manage Activity Feed",
      icon: "/icons/profile/settings/bell.svg",
      onClick: () => setType(OPTION_TYPES.ACTIVITY),
    },
    {
      title: "Manage Email Notifications",
      icon: "/icons/profile/settings/mail.svg",
      onClick: () => setType(OPTION_TYPES.EMAIL),
      style: { paddingTop: "3px" },
    },
    */
    {
      title: "Contact Us",
      icon: "/icons/profile/settings/chat.svg",
      onClick: () => window.open("/contact", "_blank"),
    },
    /*
    {
      title: "Terms of Service",
      icon: "/icons/error.svg",
      onClick: () => window.open("/terms-of-use", "_blank"),
    },
    {
      title: "Privacy Policy",
      icon: "/icons/error.svg",
      onClick: () => window.open("/privacy", "_blank"),
    },
    */
  ];

  const SUPPORT_ACTIONS = [
    /*
    {
      title: "Get Help",
      icon: "/icons/profile/settings/help.svg",
      onClick: () => setType(OPTION_TYPES.HELP),
    },
    {
      title: "Send Feedback",
      icon: "/icons/profile/settings/chat.svg",
      onClick: () => setType(OPTION_TYPES.FEEDBACK),
    },
    */
    {
      title: "Contact Us",
      icon: "/icons/profile/settings/chat.svg",
      onClick: () => window.open("/contact", "_blank"),
    },
    {
      title: "Terms of Service",
      icon: "/icons/error.svg",
      onClick: () => window.open("/terms-of-use", "_blank"),
    },
    {
      title: "Privacy Policy",
      icon: "/icons/error.svg",
      onClick: () => window.open("/privacy", "_blank"),
    },
  ];

  const openNotification = () => {
    const key = `open${Date.now()}`;
    api.open({
      description: (
        <Row>
          <Col span={22}>Password changed successfully!</Col>
          <Col span={2}></Col>
        </Row>
      ),
      key,
      duration: 10,
      className: "report-notification",
      closeIcon: <CloseIcon src="/icons/x-icon-gray.svg" alt="close" />,
    });
  };

  const onLogout = async () => {
    try {
      await firebaseAuth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  const showCancel = type && type !== OPTION_TYPES.DELETE;
  const showCancelText =
    type &&
    (type === OPTION_TYPES.FEEDBACK ||
      type === OPTION_TYPES.HELP ||
      type === OPTION_TYPES.PASSWORD);

  return (
    <>
      {contextHolder}
      <StyledCustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setType(null);
        }}
        isCentered={true}
        width={450}
      >
        {type && showCancel && (
          <>
            <CancelContainer>
              <CancelButton
                onClick={() => {
                  if (type === OPTION_TYPES.PASSWORD) {
                    setType(OPTION_TYPES.ACCOUNT);
                  } else {
                    setType(null);
                  }
                }}
              >
                <LeftCarat src="/icons/left-carat-lavender.svg" />
                {showCancelText && <CancelText>Cancel</CancelText>}
              </CancelButton>
            </CancelContainer>
            <Spacer $marginTop="1rem" />
          </>
        )}

        {!type && (
          <>
            <SettingsModalHeader>Settings</SettingsModalHeader>
            <InnerContainer>
              <Section>
                <Subheader>Account Settings</Subheader>
                {ACCOUNT_ACTIONS.map((item) => (
                  <SettingsItem key={item.title} item={item} />
                ))}
              </Section>

              {/*
              <Divider />
              <Section>
                <Subheader>Support</Subheader>
                {SUPPORT_ACTIONS.map((item) => (
                  <SettingsItem key={item.title} item={item} />
                ))}
              </Section>
              <Divider />
              */}
              <Spacer $marginTop="12px" />
              <LogOut
                size="medium-small"
                variant="ghost"
                onClick={onLogout}
                width="auto"
              >
                <Icon src={LOGOUT_ICON} alt="" />
                Log Out
              </LogOut>
            </InnerContainer>
          </>
        )}

        {/* Manager Account */}
        {type === OPTION_TYPES.ACCOUNT && <ManageAccount setType={setType} />}

        {/* Manage Activity Feed */}
        {type === OPTION_TYPES.ACTIVITY && (
          <>
            <SettingsModalHeader>Activity Feed Settings</SettingsModalHeader>
            <FeedSettingsList />
            <Spacer $marginTop="-2.75rem" />
          </>
        )}

        {/* Manage Email Notifications */}
        {type === OPTION_TYPES.EMAIL && null}

        {/* Get Help & Send Feedback */}
        {(type === OPTION_TYPES.HELP || type === OPTION_TYPES.FEEDBACK) && (
          <ContactSupport type={type} onClose={() => setType(null)} />
        )}

        {/* Delete Account */}
        {type === OPTION_TYPES.DELETE && (
          <DeleteUserModalContent onCancel={() => setType(null)} />
        )}

        {/* Reset Password */}
        {type === OPTION_TYPES.PASSWORD && (
          <ChangePassword
            onSuccess={() => {
              setType(OPTION_TYPES.ACCOUNT);
              openNotification();
            }}
          />
        )}
      </StyledCustomModal>
    </>
  );
};

const StyledCustomModal = styled(CustomModal)`
  color: var(--color-text);
`;

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  left: 22px;
  top: 18px;
`;

const CancelButton = styled.button`
  font-weight: 600;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const CancelText = styled.span``;

const CloseIcon = styled.img`
  height: 10px;
  width: 10px;
  position: absolute;
  opacity: 0.5;
`;

/*
const Divider = styled.div`
  margin-top: 10px;
  margin-bottom: 6px;
  background: #35383f;
  height: 1px;
`;
*/

const Icon = styled.img`
  text-align: left;
`;

const InnerContainer = styled.div`
  text-align: left;
  padding-bottom: 10px;
`;

const LeftCarat = styled.img`
  height: 24px;
  margin: 6px 2px 6px auto;
  filter: brightness(0);
`;

const LogOut = styled(Button)`
  color: var(--exitColor);
  text-align: left;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  padding: 0;
  gap: 8px;
`;

export const SettingsModalHeader = styled.div`
  font-weight: 600;
  text-align: center;
  display: block;
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  padding-top: 10px;
`;

const Section = styled.div`
  margin: 1.25rem auto;
  gap: 24px;
  display: grid;
`;

const Subheader = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

export default SettingsModal;
