import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  APP_NAME,
  APP_SUPPORT_EMAIL,
  APP_URL,
  FULL_COMPANY_NAME,
} from "../helpers/constants";
import { Spacer } from "../styles/CommonElements";

const APP_PRIVACY_URL = `https://${APP_URL}/privacy`;
const APP_TERMS_URL = `https://${APP_URL}/terms-of-use`;

export default function TermsOfService({ mustAcceptTos = false }) {
  return (
    <Container $mustAcceptTos={mustAcceptTos}>
      <MainHeader>Terms of Service</MainHeader>
      <Spacer $marginTop="2rem" />
      <TextContainer $mustAcceptTos={mustAcceptTos}>
        <TextSection>
          {APP_NAME} Services ("{APP_NAME}" or "Services") are provided by{" "}
          {FULL_COMPANY_NAME} ("We", "Our", "{APP_NAME}") to you ("User" or
          "Users").
        </TextSection>
        <TextSection>
          If you use any of our Services, then you agree to these Terms of
          Services and our Privacy Policy (collectively the "Agreement").
        </TextSection>
        <TextSection>
          <strong>Last Updated:</strong> March 27, 2025
        </TextSection>

        <Header>Acceptance of the Terms of Service</Header>
        <List>
          <ListItem>
            By using the {APP_NAME} website or any other {APP_NAME} product or
            service provided to you by {APP_NAME} (collectively the "Services"),
            you signify your agreement to: 1) these terms and conditions (the
            "Terms of Service"); and 2) our Privacy Policy (found at{" "}
            <Link to="/privacy">{APP_PRIVACY_URL}</Link>). Although we will
            attempt to notify you when major changes are made to these Terms of
            Service, you should periodically review the most up to date version
            (found at <Link to="/terms-of-use">{APP_TERMS_URL}</Link>).{" "}
            {APP_NAME} may, in its sole discretion, modify or revise these Terms
            of Service at any time. All modifications to the Agreement that we
            post on the Services will be effective immediately upon posting, and
            your continued use of the Services after we post changes to the
            Agreement will confirm your acceptance of the changes. If you do not
            agree to the modified Agreement, you must stop using the Services.
            Nothing in these Terms of Service shall be deemed to confer any
            third party rights.
          </ListItem>
          <ListItem>
            If you are using our Services on behalf of a business, that business
            accepts these terms. It will hold harmless and indemnify {APP_NAME}{" "}
            and its officers, agents, and employees from any claim, suit or
            action arising from or related to the use of the Services or
            violation of these terms, including any liability or expense arising
            from claims, losses, damages, suits, judgments, litigation costs and
            attorneys' fees.
          </ListItem>
        </List>

        <Header>HIPAA Compliance</Header>
        <List>
          <ListItem>
            {APP_NAME} is designed for use by healthcare providers and cosmetic
            surgeons ("Providers") to visualize potential outcomes of cosmetic
            procedures for their patients. When used by Providers for this
            purpose, {APP_NAME} functions as a Business Associate under the
            Health Insurance Portability and Accountability Act of 1996
            ("HIPAA").
          </ListItem>
          <ListItem>
            Providers who use our Services to process, store, or transmit
            Protected Health Information ("PHI") as defined under HIPAA must
            execute a Business Associate Agreement ("BAA") with {APP_NAME}{" "}
            before such use. The BAA will govern the treatment of PHI and, in
            the event of any conflict between these Terms of Service and the
            BAA, the terms of the BAA will control with respect to PHI.
          </ListItem>
          <ListItem>
            Providers are responsible for ensuring they have appropriate patient
            authorizations and/or consent as required by HIPAA and other
            applicable laws before uploading or inputting patient information
            into our Services.
          </ListItem>
          <ListItem>
            By submitting patient information to our Services, Providers
            represent and warrant that they have obtained a HIPAA-compliant
            authorization from each patient permitting: (a) the use of the
            patient's PHI for creating visualization simulations; (b) the
            storage of such information within our secure platform; and (c) the
            disclosure of such information to {APP_NAME} as a Business Associate
            for the purpose of providing the Services.
          </ListItem>
          <ListItem>
            Providers acknowledge and agree that they have informed patients
            that their information will be processed through our platform to
            create procedure visualizations, and that patients understand that{" "}
            {APP_NAME}, as a Business Associate, will have access to their
            information solely for the purposes described in the authorization
            and the BAA.
          </ListItem>
          <ListItem>
            {APP_NAME} does not require or request that patients waive any of
            their rights under HIPAA, and any such waiver would be invalid and
            unenforceable. Rather, {APP_NAME} relies on proper authorizations
            obtained by Providers in compliance with HIPAA and applicable law.
          </ListItem>
        </List>

        <Header>Ability to Accept the Terms of Service</Header>
        <List>
          <ListItem>
            To use the Services, you must be at least eighteen (18) years old
            (or the minimum permissible age under the applicable law of your
            jurisdiction, if higher) ("Minimum Age"). The use of the Service by
            anyone under the Minimum Age is unauthorized and unlicensed. By
            using the Service, you represent and warrant that you meet the
            foregoing requirement. If you do not meet this requirement, you may
            not access or use the Service.
          </ListItem>
          <ListItem>
            If you are a Provider, you represent and warrant that you are duly
            licensed and authorized to practice in your jurisdiction and to use
            our Services for professional medical purposes.
          </ListItem>
        </List>

        <Header>Service</Header>
        <List>
          <ListItem>
            These Terms of Service apply to all users of {APP_NAME} Services,
            including users who are also contributors of Content to the Service.
            "Content" includes the text, software, images, photos, videos,
            patient data, medical visualizations, audiovisual combinations,
            interactive features and other Content you may view on, access
            through, or contribute to the Service. The Service includes all
            aspects of any
            {APP_NAME} product, including but not limited to software and
            services offered via the {APP_NAME} website.
          </ListItem>
          <ListItem>
            The Services provided by {APP_NAME} include AI-powered visualization
            technology for cosmetic procedures that allows Providers to show
            patients potential outcomes of procedures. These visualizations are
            intended for informational purposes only and do not guarantee
            specific surgical or procedural results.
          </ListItem>
          <ListItem>
            The Service may contain links to third party websites that are not
            owned or controlled by {APP_NAME}. {APP_NAME} has no control over,
            and assumes no responsibility for, the content, privacy policies, or
            practices of any third party websites. In addition, {APP_NAME} will
            not and cannot censor or edit the content of any third-party site.
            By using the Service, you expressly relieve {APP_NAME} from any and
            all liability arising from your use of any third-party website.
          </ListItem>
          <ListItem>
            Accordingly, we encourage you to be aware when you leave a{" "}
            {APP_NAME} Service and to read the terms and conditions and other
            policies of each other website that you visit.
          </ListItem>
        </List>

        <Header>{APP_NAME} User Accounts</Header>
        <List>
          <ListItem>
            In order to access {APP_NAME} Services, you will have to create a{" "}
            {APP_NAME} user account. You may never use another's user account
            without permission. When creating your user account, you must
            provide accurate and complete information. You are solely
            responsible for the activity that occurs on your account, and you
            must keep your account password secure. You must notify {APP_NAME}{" "}
            immediately of any breach of security or unauthorized use of your
            account.
          </ListItem>
          <ListItem>
            For Providers, we may require verification of your professional
            credentials before granting full access to certain features of the
            Services. You agree to provide accurate and current information
            regarding your professional status, licensing, and practice.
          </ListItem>
          <ListItem>
            Although {APP_NAME} will not be liable for your losses caused by any
            unauthorized use of your account, you may be liable for the losses
            of {APP_NAME} or others due to such unauthorized use.
          </ListItem>
        </List>

        <Header>General Use of the Service</Header>
        <TextSection>
          {APP_NAME} hereby grants you permission to access and use their
          Services as set forth in these Terms of Service, provided that:
        </TextSection>
        <List>
          <ListItem>
            You do not alter or modify any part of the Service.
          </ListItem>
          <ListItem>
            You agree not to use or launch any automated system, including
            without limitation, "robots," "spiders," or "offline readers," that
            accesses the Service in a manner that attempts to forge the presence
            of a user accessing the site.
          </ListItem>
          <ListItem>
            You agree not to collect or harvest any personally identifiable
            information, including user account names, from the Service, nor to
            use the communication systems provided by the Service for any
            commercial solicitation purposes.
          </ListItem>
          <ListItem>
            You agree not to use the Services to upload, store, or process
            sensitive personal information except as expressly permitted by
            these Terms and, if applicable, your BAA.
          </ListItem>
          <ListItem>
            In your use of the Service, you will comply with all applicable
            laws, including HIPAA and other privacy and data protection laws
            applicable to patient information.
          </ListItem>
          <ListItem>
            {APP_NAME} reserves the right to discontinue or otherwise alter or
            modify any aspect of the Service at any time.
          </ListItem>
        </List>

        <Header>Your Content and Conduct</Header>
        <List>
          <ListItem>
            As a {APP_NAME} account holder you may submit Content to certain{" "}
            {APP_NAME} Services, including images and patient data for procedure
            visualization.
          </ListItem>
          <ListItem>
            You shall be solely responsible for your own Content and the
            consequences of submitting and publishing your Content on a
            {APP_NAME} Service.
          </ListItem>
          <ListItem>
            For Providers, you represent and warrant that you have obtained all
            necessary patient authorizations, consents, and permissions required
            by applicable law before uploading or inputting any patient
            information into the Services. You are solely responsible for
            compliance with patient consent requirements, privacy laws, and
            professional standards related to your use of the Services.
          </ListItem>
          <ListItem>
            In order to operate the Service, we must obtain from you certain
            license rights in your submitted Content so that actions we take in
            operating the Service are not considered legal violations.
            Accordingly, by using the Service and submitting your Content, you
            grant us a license to access, use, host, cache, store, reproduce,
            transmit, display, publish, distribute, and modify your Content. You
            agree that these rights and licenses are royalty free, transferable,
            sub-licensable, worldwide and irrevocable, and include a right for
            us to make your Content available to, and pass these rights along
            to, others with whom we have contractual relationships related to
            the provision of the Services, and to otherwise permit access to or
            disclose your Content to third parties if we determine such access
            is necessary to comply with our legal obligations. To the fullest
            extent permitted by applicable law, the Company reserves the right,
            and has absolute discretion, to remove, screen, edit, or delete any
            of your Content at any time, for any reason, and without notice.
          </ListItem>
        </List>

        <Header>Medical Disclaimer</Header>
        <List>
          <ListItem>
            {APP_NAME} provides a visualization tool for cosmetic procedures.
            The visualizations generated by our Services are intended to be used
            solely as informational aids and do not constitute medical advice,
            diagnoses, or treatment recommendations.
          </ListItem>
          <ListItem>
            The visualizations provided by {APP_NAME} represent potential
            outcomes and are not guarantees of actual results. Individual
            results may vary significantly based on numerous factors including,
            but not limited to, patient anatomy, healing characteristics,
            surgical technique, and post-operative care.
          </ListItem>
          <ListItem>
            Providers should use {APP_NAME} visualizations as supplementary
            tools only and not as a replacement for professional medical
            judgment. Patients should always consult with their healthcare
            provider about the risks, benefits, limitations, and alternatives of
            any medical procedure.
          </ListItem>
          <ListItem>
            {APP_NAME} does not practice medicine or provide medical services.
            The responsibility for the use of visualizations in patient care
            rests solely with the healthcare provider.
          </ListItem>
          <ListItem>
            By using our Services, patients acknowledge that they have provided
            appropriate authorization to their healthcare provider for the
            creation of procedural visualizations and understand that such
            visualizations are for informational purposes only.
          </ListItem>
        </List>

        <Header>Termination</Header>
        <List>
          <ListItem>
            You have the right to opt out of the Services at anytime by deleting
            your account (can be found within your Profile)
          </ListItem>
          <ListItem>
            Users, who violate these Terms of Service, tamper with the operation
            of any program, or engage in any conduct that is detrimental or
            unfair to {APP_NAME}, the program or any other users (in each case
            as determined by {APP_NAME}' sole discretion) are subject to
            suspension or cancellation of their user account(s).
          </ListItem>
          <ListItem>
            If your account is terminated or suspended, we will ensure the
            secure handling of any PHI in accordance with HIPAA and the terms of
            the applicable BAA.
          </ListItem>
          <ListItem>
            {APP_NAME} will terminate a user's access to the Service if, under
            appropriate circumstances, the user is determined to be a repeat
            infringer.
          </ListItem>
          <ListItem>
            {APP_NAME} reserves the right to decide whether Content violates
            these Terms of Service for reasons other than copyright
            infringement, such as, but not limited to, pornography, obscenity,
            or excessive length. {APP_NAME} may at any time, without prior
            notice and in its sole discretion, remove such Content and/or
            terminate a user's account for submitting such material in violation
            of these Terms of Service.
          </ListItem>
        </List>

        <Header>Data Security</Header>
        <List>
          <ListItem>
            {APP_NAME} implements technical, administrative, and physical
            safeguards designed to protect your information, including PHI if
            applicable, in accordance with industry standards and applicable
            laws.
          </ListItem>
          <ListItem>
            Our data security measures include, but are not limited to,
            encryption of data in transit and at rest, access controls, regular
            security assessments, and staff training on data protection.
          </ListItem>
          <ListItem>
            Despite our security measures, no system can be 100% secure, and we
            cannot guarantee the absolute security of your information. You are
            responsible for maintaining the security of your account credentials
            and for any activity that occurs under your account.
          </ListItem>
          <ListItem>
            In the event of a security breach affecting your personal
            information or PHI, we will notify you in accordance with applicable
            laws and regulations, including HIPAA Breach Notification
            requirements if applicable.
          </ListItem>
        </List>

        <Header>Warranties and Disclaimers</Header>
        <List>
          <ListItem>
            Other than as expressly set out in these terms or in additional
            terms, {APP_NAME} will not make any specific promises about the
            Service. For example, {APP_NAME} will not make any commitments about
            the content within the service, the specific function of the Service
            or their reliability, availability, or ability to meet your needs.
          </ListItem>
          <ListItem $capitalize={true}>
            {APP_NAME} PROVIDES THE SERVICES "AS IS" AND "AS AVAILABLE." THE
            ACCURACY OF THE VISUALIZATIONS IS DEPENDENT ON MULTIPLE FACTORS,
            INCLUDING THE QUALITY OF IMAGES PROVIDED, PATIENT CHARACTERISTICS,
            AND THE COMPLEXITY OF THE DESIRED COSMETIC PROCEDURE. {APP_NAME}{" "}
            DOES NOT GUARANTEE THAT VISUALIZATIONS WILL ACCURATELY PREDICT
            ACTUAL SURGICAL OUTCOMES.
          </ListItem>
          <ListItem>
            Some jurisdictions provide for certain warranties, like the implied
            warranty of merchantability, fitness for a particular purpose and
            non-infringement. To the extent permitted by law, we exclude all
            warranties.
          </ListItem>
        </List>

        <Header>Limitation of Liability</Header>
        <TextSection>
          The Service will be governed, construed and interpreted under the laws
          of the United States. Members agree to be bound by these Terms of
          Service and by the decisions of {APP_NAME}, which are final.{" "}
          {APP_NAME} reserves the right to suspend or cancel user accounts and
          any benefits should viruses, bugs, unauthorized human intervention or
          other causes beyond our control affect the administration, security or
          proper handling of the benefits or {APP_NAME} otherwise becomes (as
          determined in its sole discretion) incapable of running the program as
          planned. In case of bankruptcy, {APP_NAME} reserves the right to deny
          all benefits and obligations that it may have.
        </TextSection>
        <TextSection $capitalize={true}>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, {APP_NAME} WILL NOT
          BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES
          FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES,
          RESULTING FROM YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE
          THE SERVICES.
        </TextSection>
        <TextSection $capitalize={true}>
          WITHOUT LIMITING THE FOREGOING, IN NO CASE SHALL THE LIABILITY OF{" "}
          {APP_NAME} EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE
          AMOUNT YOU HAVE PAID {APP_NAME} IN THE PAST SIX MONTHS FOR THE
          SERVICES GIVING RISE TO THE CLAIM.
        </TextSection>
        <TextSection>
          THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
          EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND SHALL
          SURVIVE TERMINATION OF YOUR ACCOUNT OR THE SERVICES.
        </TextSection>
        <TextSection>
          In all cases, {APP_NAME} will not be liable for any loss or damage
          that is not reasonably foreseeable.
        </TextSection>
        <TextSection>
          In case of any conflict or inconsistency, further agreements between
          you and {APP_NAME} supersede these Terms of Service.
        </TextSection>

        <Header>Intellectual Property</Header>
        <TextSection>
          {APP_NAME} owns and retains all proprietary rights in the Services,
          including all software, algorithms, visualization technology, user
          interfaces, and other intellectual property. Nothing in these Terms of
          Service grants you any right, title, or interest in or to any{" "}
          {APP_NAME} intellectual property.
        </TextSection>
        <TextSection>
          While you retain ownership of the Content you upload to the Services,
          you grant {APP_NAME} the rights needed to operate and improve the
          Services as described in these Terms and, if applicable, your BAA.
        </TextSection>
        <TextSection>
          For Providers, you understand that the visualizations created using
          patient images and data may constitute derivative works. You are
          responsible for obtaining appropriate permissions for any use of these
          visualizations with patients or for other purposes.
        </TextSection>

        <Header>Governing Law and Dispute Resolution</Header>
        <TextSection>
          These Terms of Service shall be governed by the laws of the United
          States and the state of Missouri, without regard to its conflict of law
          provisions.
        </TextSection>
        <TextSection>
          Any dispute arising out of or relating to these Terms or the Services
          shall first be attempted to be resolved through good faith
          negotiation. If such negotiation fails, any controversy or claim shall
          be submitted to binding arbitration in accordance with the rules of
          the American Arbitration Association. The location of arbitration
          shall be St. Louis, Missouri.
        </TextSection>
        <TextSection>
          Notwithstanding the foregoing, either party may seek injunctive or
          other equitable relief to protect its intellectual property rights or
          confidential information in any court of competent jurisdiction.
        </TextSection>

        <Header>Contact</Header>
        <TextSection>
          For questions about these Terms of Service, send an email to{" "}
          <a
            href={`mailto:${APP_SUPPORT_EMAIL}`}
            rel="noreferrer"
            target="_blank"
          >
            {APP_SUPPORT_EMAIL}
          </a>
          .
        </TextSection>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div`
  color: black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 80%;
  margin: auto;
  margin-bottom: ${(props) => (props.$mustAcceptTos ? "0" : "40")}px;
  margin-top: 3rem;

  a {
    color: var(--hoverColor);
  }
`;

const TextContainer = styled.div`
  overflow-y: ${(props) => (props.$mustAcceptTos ? "auto" : "unset")};
  overflow-x: ${(props) => (props.$mustAcceptTos ? "hidden" : "unset")};
  height: ${(props) => (props.$mustAcceptTos ? "500px" : "auto")};
  max-height: ${(props) => (props.$mustAcceptTos ? "500px" : "auto")};
  box-shadow: ${(props) =>
    props.$mustAcceptTos ? "0px 4px 0px 0px rgba(0, 0, 0, 0.1)" : "none"};
  padding-right: 5%;
  padding-bottom: 32px;

  @media screen and (max-height: 825px) {
    height: ${(props) => (props.$mustAcceptTos ? "400px" : "auto")};
    max-height: ${(props) => (props.$mustAcceptTos ? "400px" : "auto")};
  }
`;

const Header = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-top: 24px;
`;

const MainHeader = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 18px;
`;

const List = styled.ul`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
`;

const ListItem = styled.li`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 10px;
  text-transform: ${(props) => (props.$capitalize ? "uppercase" : "none")};
`;

const TextSection = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.2px;
  margin-top: 18px;
  text-transform: ${(props) => (props.$capitalize ? "uppercase" : "none")};
`;
