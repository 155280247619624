import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useWindowSize } from "react-use";
import { BackLocationsContext } from "../context/BackLocationsProvider";
import { ReactSVG } from "react-svg";
import { SESSION_STORAGE } from "../helpers/constants";
import { trackEvent } from "../helpers/mixpanel";
import { AuthContext } from "../context/AuthProvider";

const bgMargin = 14;
const bgHeight = 44;

function Feed() {
  const { addBackLocation } = useContext(BackLocationsContext);
  const { user, isGuest } = useContext(AuthContext);
  const { height } = useWindowSize();

  const saveUrlToSessionStorage = () => {
    sessionStorage.setItem(SESSION_STORAGE.redirectUrl, location.pathname);
    addBackLocation();
  };

  // Calculate the number of background elements needed to fill the page
  const numberOfBgElems = Math.ceil(height / (bgHeight + bgMargin));

  return (
    <Container>
      {user && (
        <TopBanner>
          Activity Feed
          <Link
            to={`/feed/settings`}
            onClick={() => {
              addBackLocation("/feed");
              trackEvent("Clicked Alert Settings");
            }}
          >
            <SettingsGear src="/icons/gear.svg" alt="Link to settings page" />
          </Link>
        </TopBanner>
      )}
    </Container>
  );
}

export default Feed;

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopBanner = styled.div`
  position: relative;
  margin: 20px;
  text-align: center;

  color: var(--color-cream);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const BackgroundSkeleton = styled.div`
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: -1;
  padding: 0 16px;
  opacity: 0.2;

  > div {
    display: flex;
    align-items: center;
    gap: 14px;
    margin: ${bgMargin}px 0px;
  }
`;

const Circle = styled.div`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #35383f;
`;

const Line = styled.div`
  width: 100%;
  height: 14px;
  background-color: #35383f;
  border-radius: 12px;
`;

const SettingsGear = styled(ReactSVG)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  color: var(--color-cream);
`;

const EmptyFeed = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 18px;
  flex: 1;

  > div {
    /* color-gray-500 */
    background-color: rgba(35, 38, 47, 0.7);

    h3 {
      color: var(--color-cream);
      font-family: var(--font-primary);
      font-size: 24px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    p {
      color: #737784;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
    }

    img {
      transform: translateX(10px);
    }
  }
`;
