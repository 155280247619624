import React from "react";

// From https://tailwindcss.com/docs/animation
const Spinner = () => (
  <svg
    className="animate-spin inline-block"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C7.55817 0 7.2 0.358172 7.2 0.8V3.2C7.2 3.64183 7.55817 4 8 4C8.44183 4 8.8 3.64183 8.8 3.2V0.8C8.8 0.358172 8.44183 0 8 0Z"
      fill="black"
    />
    <path
      d="M8 12C7.55817 12 7.2 12.3582 7.2 12.8V15.2C7.2 15.6418 7.55817 16 8 16C8.44183 16 8.8 15.6418 8.8 15.2V12.8C8.8 12.3582 8.44183 12 8 12Z"
      fill="black"
    />
    <path
      d="M15.2 7.2C15.6418 7.2 16 7.55817 16 8C16 8.44183 15.6418 8.8 15.2 8.8H12.8C12.3582 8.8 12 8.44183 12 8C12 7.55817 12.3582 7.2 12.8 7.2H15.2Z"
      fill="black"
    />
    <path
      d="M4 8C4 7.55817 3.64183 7.2 3.2 7.2H0.8C0.358172 7.2 0 7.55817 0 8C-9.53674e-08 8.44183 0.358172 8.8 0.8 8.8H3.2C3.64183 8.8 4 8.44183 4 8Z"
      fill="black"
    />
    <path
      d="M12.5255 2.34322C12.8379 2.03081 13.3445 2.03081 13.6569 2.34322C13.9693 2.65564 13.9693 3.16218 13.6569 3.4746L11.9598 5.17165C11.6474 5.48407 11.1409 5.48407 10.8285 5.17165C10.5161 4.85923 10.5161 4.3527 10.8285 4.04028L12.5255 2.34322Z"
      fill="black"
    />
    <path
      d="M5.17154 10.8284C4.85913 10.516 4.35259 10.516 4.04017 10.8284L2.34312 12.5254C2.0307 12.8379 2.0307 13.3444 2.34312 13.6568C2.65554 13.9692 3.16207 13.9692 3.47449 13.6568L5.17154 11.9598C5.48396 11.6473 5.48396 11.1408 5.17154 10.8284Z"
      fill="black"
    />
    <path
      d="M13.6569 12.5255C13.9693 12.8379 13.9693 13.3445 13.6569 13.6569C13.3445 13.9693 12.8379 13.9693 12.5255 13.6569L10.8284 11.9598C10.516 11.6474 10.516 11.1409 10.8284 10.8285C11.1409 10.5161 11.6474 10.5161 11.9598 10.8285L13.6569 12.5255Z"
      fill="black"
    />
    <path
      d="M5.17162 5.17154C5.48404 4.85913 5.48404 4.35259 5.17162 4.04017L3.47456 2.34312C3.16214 2.0307 2.65561 2.0307 2.34319 2.34312C2.03077 2.65554 2.03077 3.16207 2.34319 3.47449L4.04025 5.17154C4.35267 5.48396 4.8592 5.48396 5.17162 5.17154Z"
      fill="black"
    />
  </svg>
);
export default Spinner;
