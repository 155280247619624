import { APP_NAME } from "./constants";
import { v4 as uuidv4 } from "uuid";

import aiPoweredPrecision from "./../images/features/precision.svg";
import increasedConversion from "./../images/features/conversion.svg";
import multiProcedureSupport from "./../images/features/multi-procedure.svg";
import seamlessIntegration from "./../images/features/seamless-integration.svg";
import streamlinedConsultationProcess from "./../images/features/consultation.svg";

const features = [
  {
    id: uuidv4(),
    icon: aiPoweredPrecision,
    title: `AI-Powered Precision`,
    desc: `${APP_NAME} uses advanced AI algorithms trained on thousands of surgical outcomes to create highly realistic visualizations tailored to each patient's unique anatomy. Our technology achieves an 87% correlation with actual post-operative results, helping you set accurate expectations and build patient trust.`,
  },
  {
    id: uuidv4(),
    icon: increasedConversion,
    title: "Increased Conversion Rates",
    desc: `Practices using ${APP_NAME} report an increase in consultation-to-procedure conversion rates. By eliminating patient uncertainty, our visualizations transform hesitant prospects into confident, committed patients who move forward with procedures more quickly and with greater satisfaction.`,
    // desc: `Practices using ${APP_NAME} report a 64% increase in consultation-to-procedure conversion rates. By eliminating patient uncertainty, our visualizations transform hesitant prospects into confident, committed patients who move forward with procedures more quickly and with greater satisfaction.`,
  },
  {
    id: uuidv4(),
    icon: streamlinedConsultationProcess,
    title: "Streamlined Consultation Process",
    desc: `${APP_NAME} reduces consultation cycles through intuitive, real-time visualizations that answer patient questions immediately. This streamlines your practice workflow, reduces follow-up appointments, and allows you to serve more patients without additional staff or resources.`,
    //desc: `${APP_NAME} reduces consultation cycles by 41% through intuitive, real-time visualizations that answer patient questions immediately. This streamlines your practice workflow, reduces follow-up appointments, and allows you to serve more patients without additional staff or resources.`,
  },
  {
    id: uuidv4(),
    icon: multiProcedureSupport,
    title: "Multi-Procedure Support",
    desc: `From hair transplants to rhinoplasty, blepharoplasty to mammoplasty, ${APP_NAME} supports a comprehensive range of cosmetic procedures. Our visualization technology adapts to your specific techniques and preferred approaches, complementing your unique surgical expertise.`,
  },
  {
    id: uuidv4(),
    icon: seamlessIntegration,
    title: "Seamless Integration",
    desc: `${APP_NAME} integrates effortlessly with your existing workflow and patient management systems. With minimal training required and full technical support, you can implement our visualization technology without disrupting your practice operations. Most business owners master the platform in under 30 minutes.`,
  },
];

export default features;
