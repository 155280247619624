import styled from "styled-components";
import HomeMain from "./HomeMain";
import HomeFeatures from "./HomeFeatures";
import HomeContact from "./HomeContact";
import HomeBanner from "./HomeBanner";
import HomeCTA from "./HomeCTA";
import { Spacer } from "../../styles/CommonElements";
import HomeCarousel from "./HomeCarousel";
import Hidden from "../../components/Hidden";
import MissionStatement from "./MissionStatement";
import HomeServices from "./HomeServices";

const DISTANCE_BETWEEN_ELEMENTS = "10rem";

export default function Home() {
  return (
    <Container>
      <HomeMain />
      <Hidden mobile>
        <Spacer $marginTop={DISTANCE_BETWEEN_ELEMENTS} />
      </Hidden>
      <Hidden desktop tablet>
        <Spacer $marginTop="5rem" />
      </Hidden>
      <HomeBanner />
      <Hidden mobile>
        <Spacer $marginTop={DISTANCE_BETWEEN_ELEMENTS} />
      </Hidden>
      <Hidden desktop tablet>
        <Spacer $marginTop="5rem" />
      </Hidden>
      <MissionStatement />
      <HomeServices />
      <HomeCarousel />
      <Hidden mobile>
        <Spacer $marginTop="2rem" />
      </Hidden>
      <HomeCTA />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
