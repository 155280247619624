import styled from "styled-components";
import CustomSheet from "../components/CustomSheet";
import { APP_NAME, zIndex } from "../helpers/constants";
import { ReactSVG } from "react-svg";
import Button from "./Button";
import { Link } from "react-router-dom";

const AuthSheet = ({ isOpen, onNavigation, onClose }) => {
  return (
    <StyledSheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[300]}
      hasCloseIcon
      headerContent={
        <>
          {/*
          <ReactSVG src="/images/logos/bodymagic-icon-body-darkblue.svg" />
          */}
        </>
      }
      tweenConfig={{ ease: "easeOut", duration: 0.3 }}
    >
      <CallToAction>
        <Heading>Log in to {APP_NAME}</Heading>
        <Link to="/login" onClick={onNavigation}>
          <Button as="div">Continue with Email</Button>
        </Link>
        {/*
        <ThirdPartyAuth cta="continue" size="medium" />
        */}
      </CallToAction>
      <SecondaryActions>
        <Terms>
          By continuing, you agree to our{" "}
          <StyledLink to="/terms-of-use" onClick={onNavigation}>
            Terms of Service
          </StyledLink>
          .
        </Terms>
        <SignUpCta>
          Don't have an account?{" "}
          <Link to="/contact" onClick={onNavigation}>
            Contact Us
          </Link>
        </SignUpCta>
      </SecondaryActions>
    </StyledSheet>
  );
};

const StyledSheet = styled(CustomSheet)`
  position: relative;
  z-index: ${zIndex.authSheet} !important;

  & .react-modal-sheet-header {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

const Heading = styled.div`
  text-align: center;
  font-family: "Arial";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

const SecondaryActions = styled.div`
  text-align: center;

  > div {
    margin-top: 24px;
    font-size: 10px;
  }

  div:last-of-type {
    font-size: 14px;
    font-weight: 600;
  }
`;

const SignUpCta = styled.div`
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;

  span {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
`;

const Terms = styled.div`
  color: #777e91;
  text-align: center;
  font-weight: 400;
  font-family: Arial;
  font-size: 10px;
  font-style: normal;
  line-height: 160%;
`;

export default AuthSheet;
