import CustomModal from "../CustomModal";
import { Spacer } from "../../styles/CommonElements";
import styled from "styled-components";
import { Col, Row } from "antd";
import { APP_NAME } from "../../helpers/constants";

const PredictionResultModal = ({
  isOpen,
  onClose,
  userUploadedImage,
  prediction,
  timeToCreate,
  selectedPatient,
}) => {
  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        width={900}
        isCentered={true}
      >
        <ModalHeader>{APP_NAME} Result</ModalHeader>
        {selectedPatient && (
          <PatientInfo>Patient: {selectedPatient.name}</PatientInfo>
        )}
        <Subheader>Created in {timeToCreate?.toFixed(2)} seconds</Subheader>
        <Spacer $marginTop="2rem" />

        <StyledRow gutter={0}>
          <Col span={12}>
            <ImageLabel>Original</ImageLabel>
            <ModalImage
              src={userUploadedImage && URL.createObjectURL(userUploadedImage)}
              alt=""
            />
          </Col>
          <Col span={12}>
            <ImageLabel>Prediction</ImageLabel>
            <ModalImage src={prediction} alt="" />
          </Col>
        </StyledRow>
        <Spacer $marginTop="2.5rem" />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(CustomModal)``;

const ModalHeader = styled.div`
  font-weight: 600;
  text-align: center;
  display: block;
  position: absolute;
  top: 26px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
  font-size: 20px;
  line-height: 24px;
  color: black;
`;

const PatientInfo = styled.div`
  line-height: 20px;
  color: #333;
  text-align: center;
  margin-top: 35px;
`;

const ImageLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
`;

const ModalImage = styled.img`
  max-height: 300px;
  width: auto;
`;

const StyledRow = styled(Row)`
  text-align: -webkit-center;
`;

const Subheader = styled.div`
  color: black;
  text-align: center;
`;

export default PredictionResultModal;
