export const allowListTable = "allowList";
export const pollTable = "polls";
export const userTable = "users";
export const storyworldTable = "storyworlds";
export const questTable = "quests";
export const creatorPollTable = "creatorPolls";
export const creatorPollVoteTable = "creatorPollVotes";
export const submissionsTable = "submissions";
export const votesTable = "votes";
export const likesTable = "likes";
export const episodesTable = "episodes";
export const subscriptionsTable = "subscriptions";
export const bookmarksTable = "bookmarks";
export const quotasTable = "generationQuotas";
export const constantsTable = "constants";
export const createdAccountsTable = "createdAccounts";
export const commentTable = "comments";
export const reportTable = "reports";
export const creatorMessageTable = "creatorMessages";
export const banTable = "bans";
export const suspensionsTable = "suspensions";
export const alertTable = "alerts"; // Individual alerts for a user
export const hasAlertTable = "hasAlerts"; // Document listened to for knowing when a new alert has been created, 1 per user
export const alertConfigsTable = "alertConfigs"; // alert configurations, 1 per user
export const promosTable = "promos";
export const predictionsTable = "predictions"; // patient predictions of how they will look with a transplant
export const patientTable = "patients";
