import styled from "styled-components";
import { Sheet } from "react-modal-sheet";
import { useContext, useEffect, useCallback } from "react";
import { ScrollOffscreenContext } from "../context/ScrollOffscreenProvider";
import { isOniOS } from "../helpers/general";
import { zIndex } from "../helpers/constants";
import { useBreakpoints } from "../lib/breakpoints";

// This can be further wrapped in a Styled component because it pass the className prop
const CustomSheet = ({
  children,
  onClose,
  headerContent,
  hasCloseIcon = false,
  ...props
}) => {
  const { addOpenSheet, removeOpenSheet } = useContext(ScrollOffscreenContext);
  const { isTabletOrAbove } = useBreakpoints();

  const handleClose = useCallback(() => {
    removeOpenSheet();
    onClose();
  }, [onClose, removeOpenSheet]);

  useEffect(() => {
    if (isTabletOrAbove && props.isOpen) {
      handleClose();
    }
  }, [isTabletOrAbove, props.isOpen, handleClose]);

  return (
    <StyledSheet
      style={{
        pointerEvents: props.isOpen ? "auto" : "none",
        background: props.isOpen ? "rgba(0,0,0,0.5)" : null,
      }}
      onOpenStart={() => {
        addOpenSheet();
      }}
      onClose={handleClose}
      $hasCloseIcon={hasCloseIcon}
      $hasheaderContent={!!headerContent}
      disableScrollLocking={isOniOS()}
      {...props}
    >
      {/* onClick -> commentInputRef.current.blur() ?  */}
      <Sheet.Container>
        {hasCloseIcon ? (
          <Sheet.Header
            style={{ textAlign: "right", marginBottom: "6px" }}
            // The library strips the class name for a custom header, which I find counter-intuitive so I am restoring it
            className="react-modal-sheet-header"
          >
            <HeaderContainer $hasheaderContent={!!headerContent}>
              {headerContent && <TopHeader>{headerContent}</TopHeader>}
              <CloseButtonWrapper
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClose();
                }}
              >
                <CloseButton
                  src="/icons/x-icon-gray.svg"
                  alt="Button to close drawer"
                />
              </CloseButtonWrapper>
            </HeaderContainer>
          </Sheet.Header>
        ) : (
          <Sheet.Header />
        )}
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </StyledSheet>
  );
};

// All classes exposed by React-Modal-Sheet
// https://github.com/Temzasse/react-modal-sheet?tab=readme-ov-file#vanilla-css
const StyledSheet = styled(Sheet)`
  z-index: ${zIndex.sheet} !important;

  *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px !important;
    height: 0px !important;
    opacity: 0;
    display: none;
    background: none;
  }

  .react-modal-sheet-header {
    box-sizing: content-box;
    min-height: 38px;
    border-bottom: ${(props) =>
      props.$hasheaderContent && "0.5px solid var(--border-light)"};
    padding-bottom: ${(props) => props.$hasheaderContent && "16px"};
    padding-top: ${(props) => props.$hasCloseIcon && "8px"};

    text-align: center;
    font-family: Arial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--color-cream);
  }

  .react-modal-sheet-container {
    /* In some instances with this package we will need to use !important, 
    because the author is applying styles inline. But a lot of them can most likely 
    be removed elsewhere, since they were probably born out of competing classes  */
    background-color: white !important;
    padding: 0px 20px 0px 20px;
    border-radius: 20px 20px 0px 0px;
  }

  /* TODO: There is horizontal overflow somewhere in both sheets */
  .react-model-sheet-content {
    padding: 12px 20px 0px 20px;
  }

  .react-modal-sheet-scroller {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .react-modal-sheet-drag-indicator {
    width: 30px !important;
    background-color: var(--color-gray) !important;
    height: 5px !important;
    flex-shrink: 0;
  }
`;

const TopHeader = styled.div`
  flex-grow: 0;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const CloseButtonWrapper = styled.div`
  padding: 10px 14px;
  position: relative;
  flex-grow: 0;
`;

const CloseButton = styled.img`
  display: inline-block;
  margin: auto;
  opacity: 0.5;

  height: auto;
  width: 24px;
  padding: 4px;
`;

export default CustomSheet;
