import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
// import { SelectedStoryworldContext } from "../context/SelectedStoryworldProvider";
// import { authRoutes } from "../helpers/authentication";
import { Layout } from "antd";
import { showHeaderUi } from "../helpers/general";

export const AppLayout = ({ children }) => {
  // const { selectedStoryworld } = useContext(SelectedStoryworldContext);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  // Eventually this can be replaced with inline checks
  const [locationTitle, setLocationTitle] = useState("");

  const location = useLocation();

  /*
  useEffect(() => {
    if (
      location.pathname === "/quests" &&
      selectedStoryworld &&
      selectedStoryworld.backgroundImageUrl
    ) {
      setBackgroundImage(selectedStoryworld.backgroundImageUrl);
    } else if (authRoutes.includes(location.pathname)) {
      setBackgroundColor("black");
    } else {
      setBackgroundImage("");
    }

    if (location.pathname === "/login") {
      setLocationTitle("login");
    } else {
      setLocationTitle("");
    }
  }, [location.pathname, selectedStoryworld]);
  */

  return (
    <Container
      $backgroundImage={backgroundImage}
      $backgroundColor={backgroundColor}
      $location={locationTitle}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: center;
  /* padding-left: calc(100vw - 100%); */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  background-image: ${({ $backgroundImage }) => $backgroundImage ?? "none"};
`;

export const ContentLayout = ({ children }) => {
  const location = useLocation();

  return <StyledLayout $location={location}>{children}</StyledLayout>;
};

const BASE_MIN_HEIGHT = "100vh";

const StyledLayout = styled(Layout)`
  // 60px is the current height of the header nav
  min-height: ${(props) =>
    showHeaderUi(props.$location)
      ? `calc(${BASE_MIN_HEIGHT} - 60px)`
      : BASE_MIN_HEIGHT};
`;
