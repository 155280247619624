import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

function Dropzone(props) {
  // Image compression function
  const compressImage = useCallback(
    (file, maxWidth = 1200, maxHeight = 1200, quality = 0.8) => {
      return new Promise((resolve, reject) => {
        // Create file reader to read the image
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target?.result;

          img.onload = () => {
            // Calculate new dimensions while maintaining aspect ratio
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
              }
            }

            // Create canvas and draw the resized image
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            if (!ctx) {
              reject(new Error("Could not get canvas context"));
              return;
            }

            ctx.drawImage(img, 0, 0, width, height);

            // Convert to blob with the specified quality
            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  reject(new Error("Could not create blob"));
                  return;
                }

                // Create new file from blob
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });

                // Log compression stats
                console.log(
                  `Original size: ${Math.round(
                    file.size / 1024
                  )}KB, Compressed: ${Math.round(compressedFile.size / 1024)}KB`
                );

                resolve(compressedFile);
              },
              file.type,
              quality
            );
          };

          img.onerror = () => {
            reject(new Error("Failed to load image"));
          };
        };

        reader.onerror = () => {
          reject(new Error("Failed to read file"));
        };
      });
    },
    []
  );

  // Handle file drop
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        try {
          // Get the first file (assuming single file upload)
          const originalFile = acceptedFiles[0];

          // Compress the image
          const compressedFile = await compressImage(originalFile);

          // Handle the compressed file using any available handler prop
          if (props.onFileDrop) {
            props.onFileDrop(compressedFile);
          } else if (props.onImageDropped) {
            props.onImageDropped(compressedFile);
          } else if (props.onChange) {
            props.onChange(compressedFile);
          } else if (props.handleUpload) {
            props.handleUpload(compressedFile);
          } else {
            console.warn(
              "No handler function provided for uploaded file in Dropzone component"
            );
          }
        } catch (error) {
          console.error("Error compressing image:", error);
          // Fall back to original file if compression fails
          const handler =
            props.onFileDrop ||
            props.onImageDropped ||
            props.onChange ||
            props.handleUpload;
          if (handler) {
            handler(acceptedFiles[0]);
          }
        }
      }
    },
    [compressImage, props]
  );

  // Setup dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/webp": [".webp"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
  });

  // Don't render if predictions exist or image already uploaded
  if (props.predictions?.length) return null;
  if (props.userUploadedImage) return null;

  return (
    <Container
      className="absolute z-50 flex w-full h-full text-gray-500 text-sm text-center cursor-pointer select-none"
      {...getRootProps()}
    >
      <div className="m-auto">
        <input {...getInputProps()} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 94.5%;
  height: 80%;
  background-color: #e6e6e6;
  background-image: url("/images/dashboard/profile.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const UploadIconContainer = styled.span`
  display: inline-flex;
`;

export default Dropzone;
