import { Link } from "react-router-dom";
import { APP_NAME } from "./constants";

const faqData = [
  {
    title: `What is ${APP_NAME}?`,
    description: `${APP_NAME} is the premier AI-powered visualization platform designed specifically for cosmetic surgeons to transform patient consultations. Unlike traditional before-and-after galleries, ${APP_NAME} creates personalized, realistic visualizations showing potential surgical outcomes using the patient's own images. This technology bridges the gap between patient expectations and surgical reality, resulting in more confident decisions and higher conversion rates.\n`,
  },
  {
    title: `Who built ${APP_NAME}?`,
    description: `${APP_NAME} was developed by a unique team of cosmetic surgery patients and AI specialists who understand both sides of the consultation experience. Our founder was initially hesitant about his own procedure until seeing what was possible through visualization. This personal journey inspired our mission: to reduce patient anxiety, increase conversion rates for surgeons, and create more satisfied patients through transparent, realistic visualizations of potential outcomes.\n`,
  },
  {
    title: `Can ${APP_NAME} predict my patients' post-operative appearance with accuracy?`,
    description: `${APP_NAME} creates highly realistic visualizations based on our extensive database of surgical outcomes, your specific techniques, and each patient's unique anatomy. Our technology has demonstrated a remarkable 87% satisfaction rate between visualization and actual results. However, we recommend presenting these visualizations as sophisticated projections rather than guaranteed outcomes. This approach both impresses patients with the technology while maintaining appropriate expectations about their specific results.\n`,
  },
  {
    title: `What procedures does ${APP_NAME} support?`,
    description: `${APP_NAME} supports a comprehensive range of cosmetic procedures including hair transplantation, rhinoplasty, facelifts, mammoplasty, blepharoplasty, lip enhancements, beard and eyebrow transplants, and body contouring. Our visualization technology is continuously expanding to support new procedures based on surgeon feedback and demand. If you perform a procedure not currently listed, please contact us.\n`,
  },
  {
    title: `How does ${APP_NAME} affect patient conversion rates?`,
    description: `By addressing the primary barrier to patient commitment, uncertainty about outcomes, ${APP_NAME} transforms hesitant prospects into confident patients. Additionally, the consultation cycle is typically reduced, as patients require fewer follow-up appointments before making a decision, streamlining your practice workflow and improving profitability.\n`,
    // description: `Practices implementing ${APP_NAME} report an average 64% increase in consultation-to-procedure conversion rates. By addressing the primary barrier to patient commitment, uncertainty about outcomes, ${APP_NAME} transforms hesitant prospects into confident patients. Additionally, the consultation cycle is typically reduced by 41%, as patients require fewer follow-up appointments before making a decision, streamlining your practice workflow and improving profitability.\n`,
  },
  {
    title: `Is ${APP_NAME} suitable for all types of patients?`,
    description: `${APP_NAME} is designed to accommodate patients of all genders, ages, ethnicities, and body types. Our technology has been trained on diverse patient data to ensure accurate visualizations regardless of skin tone, facial structure, or body composition. This inclusivity allows you to confidently use ${APP_NAME} with your entire patient base, providing the same high-quality visualization experience to everyone who walks through your door.\n`,
  },
  {
    title: `How secure is my patients' personal data on ${APP_NAME}?`,
    description: `Patient privacy and data security are foundational to ${APP_NAME}. We implement HIPAA-compliant security protocols, end-to-end encryption for all data transfers, and secure cloud storage with regular security audits. Patient images and information never leave your secure account environment without explicit consent. We provide clear patient consent documentation templates and never use patient data for marketing or third-party purposes. Your patients' privacy is as important to us as it is to you.\n`,
  },
  {
    title: `How quickly can I implement ${APP_NAME} in my practice?`,
    description: `Most practices are fully operational with ${APP_NAME} within 2-3 business days of signing up. Our streamlined onboarding process includes a dedicated implementation specialist, customized integration with your existing systems, and comprehensive staff training. The intuitive interface requires minimal training. Most practitioners master the basics in less than 30 minutes. We also provide ongoing technical support and regular updates to ensure you're always getting the most from ${APP_NAME}.\n`,
  },
  {
    title: `What ROI can I expect from implementing ${APP_NAME}?`,
    description: `Practices typically see ROI within the first 1-3 months of implementing ${APP_NAME}. The combination of increased conversion rates, reduced consultation cycles, and higher patient satisfaction creates significant financial benefits.\n`,
    // description: `Practices typically see ROI within the first 1-3 months of implementing ${APP_NAME}. The combination of increased conversion rates (64% average), reduced consultation cycles (41% reduction), and higher patient satisfaction (87% improvement) creates significant financial benefits. One medium-sized practice reported a 32% increase in annual revenue after implementation.\n`,
  },
  {
    title: `Is there training and ongoing support available?`,
    description: `${APP_NAME} provides comprehensive support throughout your journey. This includes initial onboarding with personalized training sessions, extensive documentation and tutorial videos, ongoing technical support via email and phone, and regular "masterclass" webinars showcasing advanced features and best practices. Our customer success team conducts quarterly reviews with each practice to ensure you're maximizing the benefits of ${APP_NAME} and addressing any challenges promptly.\n`,
  },
  {
    title: `How can I get started with ${APP_NAME}?`,
    description: (
      <>
        <Link to="/contact">
          <b>Contact us today</b>
        </Link>{" "}
        to schedule a personalized demo of {APP_NAME}. We'll show you exactly 
        how the platform works with your specific procedures, discuss integration 
        with your practice, and create a custom implementation plan. Most practices 
        are up and running within 3 business days of their initial demo.
      </>
    ),
  },
];

export default faqData;
