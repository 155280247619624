import React, { createRef, useContext, useEffect } from "react";
import { MainHeader, Spacer } from "../styles/CommonElements";
import predictionModel from "../lib/firebase/predictionModel";
import { APP_ACTION } from "../helpers/constants";
import { useQuery } from "@tanstack/react-query";
import { predictionKeys } from "../lib/queryKeys";
import { AuthContext } from "../context/AuthProvider";
import Carousel from "better-react-carousel";
import styled from "styled-components";
import { Link } from "react-router-dom";

const predictionPageAmount = 24;

export default function RecentPredictions({ patientName, patientId }) {
  const { userInfo } = useContext(AuthContext);
  let carouselRef = createRef();

  const { data: recentPredictions, refetch } = useQuery({
    queryKey: [
      ...predictionKeys.user(userInfo?.id || "anonymous"),
      patientId || "all",
    ],
    queryFn: () => {
      if (!userInfo?.id) return [];

      // If patientId is provided, filter by both creator and patientId
      if (patientId) {
        return predictionModel.getMany(
          ["creator", "==", userInfo.id],
          ["patient", "==", patientId],
          ["sort", "createdAt", "desc"],
          ["limit", predictionPageAmount]
        );
      }
      // Otherwise, just filter by creator
      return predictionModel.getMany(
        ["creator", "==", userInfo.id],
        ["sort", "createdAt", "desc"],
        ["limit", predictionPageAmount]
      );
    },
    placeholderData: [],
    enabled: !!userInfo?.id,
  });

  // Refetch predictions when patientId changes
  useEffect(() => {
    if (userInfo?.id) {
      refetch();
      // Reset carousel to first slide when patient changes
      if (carouselRef.current) {
        carouselRef.current.goToSlide(0);
      }
    }
  }, [patientId, userInfo?.id, refetch]); // carouselRef is not needed here

  return (
    recentPredictions?.length > 0 && (
      <div>
        <HeaderContainer>
          <StyledMainHeader>Recent {APP_ACTION}</StyledMainHeader>
          <Spacer $marginTop="0.5rem" />
          <Subheader>
            {patientId
              ? `View past ${APP_ACTION} for ${patientName}. Click on one to download it.`
              : `See past ${APP_ACTION} you have created. Click on one to download it.`}
          </Subheader>
        </HeaderContainer>
        <Spacer $marginTop="2rem" />
        <CarouselContainer>
          <StyledCarousel
            ref={carouselRef}
            cols={3}
            rows={1}
            gap={8}
            showDots={false}
          >
            {recentPredictions.map((prediction) => (
              <Carousel.Item key={prediction.id}>
                <Link
                  to={`${prediction.output}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PredictionImage src={prediction.output} alt="" />
                  {/*
                  <PredictionDate>
                    {prediction?.createdAt 
                      ? new Date(prediction?.createdAt?.toDate()).toLocaleDateString() 
                      : ""}
                  </PredictionDate>
                  */}
                </Link>
              </Carousel.Item>
            ))}
          </StyledCarousel>
        </CarouselContainer>
      </div>
    )
  );
}

const CarouselContainer = styled.div`
  width: 90%;
  margin: auto;
`;

const HeaderContainer = styled.div`
  margin: auto;
  margin-left: 10%;
  text-align: left;
  width: 90%;
`;

const PredictionImage = styled.img`
  object-fit: cover;
  height: 100%;
`;

const PredictionDate = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
`;

const StyledCarousel = styled(Carousel)`
  max-height: 350px;
`;

const StyledMainHeader = styled(MainHeader)`
  text-align: left;
`;

const Subheader = styled.div`
  font-size: 16px;
  margin: auto;
  text-align: left;
`;
