"use client";
// ----- Components Import -----
import { styled } from "styled-components";

// ----- Assets Import -----
import {
  FieldContainer,
  InputArea,
  Spacer,
  StyledCheckbox,
  StyledInput,
} from "../../styles/CommonElements";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useState } from "react";
import { device, useBreakpoints } from "../../lib/breakpoints";
import { Col, message, Row } from "antd";
import { APP_NAME } from "../../helpers/constants";
import { Link } from "react-router-dom";
import { sendContactForm } from "../../helpers/contact-form";
import Spinner from "../../components/Spinner";

const CONTACT_BANNER_BG = "/images/contact/office.svg";

export default function ContactPage() {
  const { isMobile } = useBreakpoints();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formMessage, setFormMessage] = useState("");

  const {
    control,
    formState: { isValid, errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      agreedToTerms: false,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);

    const data = {
      name,
      email,
      phone,
      message: formMessage,
    };

    const result = await sendContactForm(data);
    if (result.success) {
      message.success("Thanks for contacting us! We'll be in touch soon.");
      setSuccessfullySubmitted(true);
      // await creatorMessageModel.create({ subjectId: comment.id });
      /*
      trackEvent("Creator Message Sent", {
        storyworld_id: storyworld.id,
        storyworld_name: storyworld.title,
      });
      */
      // setMessage("");
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <StyledRow gutter={72}>
          {!isMobile && (
            <StyledLeftCol span={12}>
              <Title>Book a demo</Title>
              <Spacer $marginTop="1rem" />
              <Subtitle>
                Interested in learning how {APP_NAME} can transform your
                business? Fill out the form and an expert from our team will
                contact you shortly to arrange a meeting.
              </Subtitle>
            </StyledLeftCol>
          )}

          <StyledRightCol
            $displayFullHeight={isSubmitting || successfullySubmitted}
            span={isMobile ? 24 : 12}
          >
            {!isSubmitting && successfullySubmitted && (
              <SpinnerContainer>
                <SuccessMessage>Thanks for reaching out!</SuccessMessage>{" "}
                <SuccessMessage>
                  We'll get back to you as soon as possible.
                </SuccessMessage>
              </SpinnerContainer>
            )}
            {isSubmitting && !successfullySubmitted && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
            {!isSubmitting && !successfullySubmitted && (
              <>
                {isMobile && (
                  <Title style={{ color: "black", textAlign: "center" }}>
                    Book a demo
                  </Title>
                )}
                <StyledRow gutter={18}>
                  <StyledCol span={12}>
                    <Label>
                      Full Name<Asterisk>*</Asterisk>
                    </Label>
                    <StyledInput
                      value={name}
                      type="text"
                      placeholder=""
                      maxLength={100}
                      onChange={(e) => setName(e.target.value)}
                      $borderRadius="0px"
                    />
                  </StyledCol>
                  <StyledCol span={12}>
                    <Label>
                      Email<Asterisk>*</Asterisk>
                    </Label>
                    <StyledInput
                      value={email}
                      type="text"
                      placeholder=""
                      maxLength={100}
                      onChange={(e) => setEmail(e.target.value)}
                      $borderRadius="0px"
                    />
                  </StyledCol>
                </StyledRow>

                <Label>
                  Message<Asterisk>*</Asterisk>
                </Label>
                <StyledInputArea
                  value={formMessage}
                  maxLength={500}
                  onChange={(e) => setFormMessage(e.target.value)}
                  placeholder=""
                />

                <StyledFieldContainer
                  style={{ flexDirection: "row", gap: "0.75rem" }}
                >
                  <Controller
                    name="agreedToTerms"
                    control={control}
                    rules={{
                      required:
                        "You must agree to the terms of service before registering",
                    }}
                    render={({ field }) => (
                      <StyledCheckbox checked={field.value} {...field} />
                    )}
                  />
                  <div>
                    I agree to the
                    <StyledLink to="/terms-of-use" target="_blank">
                      Terms of Service
                    </StyledLink>
                    <Asterisk>*</Asterisk>
                  </div>
                </StyledFieldContainer>

                <Button
                  type="submit"
                  size="large"
                  rounded="slight"
                  isLoading={isSubmitting}
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    name === "" ||
                    email === "" ||
                    formMessage === ""
                  }
                >
                  Submit
                </Button>
              </>
            )}
          </StyledRightCol>
        </StyledRow>
      </StyledForm>
    </Container>
  );
}

// ----- Styles -----

const Container = styled.div`
  width: 100%;
  height: 770px;
  margin: auto;
  background-image: url(${CONTACT_BANNER_BG});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 2rem;

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
  }
`;

const Asterisk = styled.span`
  color: #ff0000;
`;

const Label = styled.div`
  color: black;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  scale: 1.1;
`;

const StyledCol = styled(Col)`
  height: fit-content;
`;

const StyledFieldContainer = styled(FieldContainer)`
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  padding: 4rem;

  @media ${device.mobile} {
    padding: 4rem 3rem;
  }
`;

const StyledInputArea = styled(InputArea)`
  font-size: 14px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: transparent;
  background: var(--color-gray-50) !important;
  padding-left: 18px;
  border-radius: 0px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
  margin: auto;
  margin-top: -20px;

  &:focus {
    border-color: transparent;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  &:disabled {
    background: var(--color-gray-450);
    pointer-events: none;
    user-select: none;
    color: #65676d;
  }
`;

const StyledLeftCol = styled(Col)`
  padding: 0rem 2.5rem;
  display: block;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media ${device.mobile} {
    padding: 2rem 1rem;
  }
`;

const StyledLink = styled(Link)`
  margin-left: 4px;
  font-weight: 600;
`;

const StyledRightCol = styled(Col)`
  padding: 2rem 2.5rem;
  display: inline-grid;
  gap: 24px;
  flex-direction: column;
  height: ${(props) => (props.$displayFullHeight ? "100%" : "fit-content")};
  align-content: center;
  border-radius: 4px;
  height: 500px;
  background: #e7eded;
  max-width: 700px;

  @media ${device.mobile} {
    padding: 0;
    height: 600px;
    padding-left: 14px;
    padding-right: 14px;
    margin: auto;
  }
`;

const StyledRow = styled(Row)`
  height: 100%;
  text-align: left;
`;

const Subtitle = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  padding-right: 4rem;

  @media ${device.tablet} {
    padding-right: 2rem;
  }

  @media ${device.mobile} {
    font-size: 14px;
    padding: 0;
  }
`;

const SuccessMessage = styled.div`
  font-size: 14px;
  width: 300px;
  margin: auto;
  font-weight: 500;
`;

const Title = styled.div`
  color: white;
  font-weight: bold;
  line-height: 100%;
  font-size: 100px;

  @media ${device.tablet} {
    font-size: 84px;
  }

  @media ${device.mobile} {
    font-size: 40px;
  }
`;
