import React from "react";
import { useLocation } from "react-router-dom";
import { StyledLink } from "../../styles/CommonElements";
import Button from "../Button";

export default function BookDemoButton({
  width = "140px",
  size = "medium-small",
}) {
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === "/contact") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <StyledLink to="/contact">
      <Button variant="primary" width={width} size={size} onClick={handleClick}>
        Book a demo
      </Button>
    </StyledLink>
  );
}
