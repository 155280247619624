import { useForm } from "react-hook-form";
import CustomModal, { ModalTitle } from "../CustomModal";
import {
  FormError,
  Highlight,
  InputHeader,
  Spacer,
  StyledInput,
} from "../../styles/CommonElements";
import { useEffect, useRef, useState } from "react";
import RevealPassword from "../RevealPassword";
import styled from "styled-components";
import { notification } from "antd";
import useLoginMutation from "../../lib/useLoginMutation";
import Button from "../Button";
import { Link } from "react-router-dom";
import {
  DIALOG_NAME,
  useOpenParamDialog,
} from "../../helpers/useDialogSearchParam";
import ForgotPassword from "./ForgotPassword";
import { APP_LOGO, APP_NAME } from "../../helpers/constants";

const LoginModal = ({ isOpen, onClose }) => {
  const [isPasswordRevealed, setPasswordRevealed] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const passwordRef = useRef();
  const openSignup = useOpenParamDialog(DIALOG_NAME.signup);

  const [api, contextHolder] = notification.useNotification();

  const { login, loginPending, loginError } = useLoginMutation({
    api,
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, isValid, errors },
  } = useForm({
    mode: "onTouched",
  });

  const onSubmit = (data) => {
    login(data, {
      onSuccess: () => {
        reset();
        onClose();
      },
      onError: () => {
        // reset dirty status but keep values (temporarily disabling login button)
        reset({}, { keepValues: true });
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <>
      {contextHolder}
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setForgotPassword(false);
        }}
        isCentered={true}
        width={500}
      >
        <LogoWrapper>
          <StyledLogo src={APP_LOGO} alt={`${APP_NAME} Logo`} />
        </LogoWrapper>
        <Spacer $marginTop="0.5rem" />
        <StyledModalTitle>
          {!forgotPassword ? `Welcome to ${APP_NAME}` : "Reset your password"}
        </StyledModalTitle>
        <Spacer $marginTop="0.5rem" />
        {!forgotPassword && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputHeader htmlFor="email">Email</InputHeader>
              <Spacer $marginTop="0.25rem" />
              <StyledInput
                id="email"
                placeholder="email@email.com"
                $borderRadius="0px"
                type="email"
                {...register(
                  "email",
                  // These inline validations can be replaced by Zod
                  {
                    required: "Please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  }
                )}
              />
              {errors.email && <FormError>{errors.email.message}</FormError>}
              <Spacer $marginTop="1rem" />

              <InputHeader htmlFor="password">Password</InputHeader>
              <Spacer $marginTop="0.25rem" />
              <div style={{ position: "relative" }}>
                <StyledInput
                  id="password"
                  placeholder="Enter Password"
                  $borderRadius="0px"
                  type={isPasswordRevealed ? "text" : "password"}
                  ref={passwordRef}
                  {...register("password", {
                    required: "Please enter your password",
                  })}
                />
                <RevealPassword
                  isRevealed={isPasswordRevealed}
                  toggleRevealed={() =>
                    setPasswordRevealed(!isPasswordRevealed)
                  }
                  passwordRef={passwordRef}
                />
              </div>
              {errors.password && (
                <FormError>{errors.password.message}</FormError>
              )}
              <ForgotPasswordSection>
                <Highlight
                  onClick={() => {
                    setForgotPassword(true);
                  }}
                >
                  Forgot password?
                </Highlight>
              </ForgotPasswordSection>

              <Spacer $marginTop="0.5rem" />

              {loginError !== "" && <LoginError>{loginError}</LoginError>}

              <Spacer $marginTop="1rem" />

              <Button
                type="submit"
                size="large"
                disabled={!isDirty || !isValid || loginPending}
              >
                Log In
              </Button>
            </form>
            {/*
            <Spacer $marginTop="1rem" />
            <OrDivider />
            <Spacer $marginTop="1rem" />
            <ThirdPartyAuth cta="Log In" />
            */}
            <Spacer $marginTop="1rem" />
            <Terms>
              By continuing, you agree to our{" "}
              <Link to="/terms-of-use" target="_blank">
                <Highlight>Terms of Service</Highlight>
              </Link>
              .
            </Terms>
            <Spacer $marginTop="1rem" />
            <SignUpCta>
              <Link to="/contact">
                Don't have an account? Click here to Contact Us
              </Link>
            </SignUpCta>

            <Spacer $marginTop="0.25rem" />
          </>
        )}
        {forgotPassword && <ForgotPassword email={getValues("email")} />}
        <Spacer $marginTop="0.5rem" />
      </CustomModal>
    </>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  height: 50px;
  width: auto;
`;

const ForgotPasswordSection = styled.div`
  text-align: right;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: 0.2px;
  margin-top: 12px;

  span {
    cursor: pointer;
  }
`;

const LoginError = styled.div`
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--color-red);
`;

const SignUpCta = styled.div`
  color: var(--color-text);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;

  span {
    cursor: pointer;
  }
`;

const StyledLogo = styled.img`
  width: 200px;
  height: auto;
  filter: brightness(0);
`;

const StyledModalTitle = styled(ModalTitle)`
  // color: var(--color-brand-complimentary);
  color: black;
`;

const Terms = styled.p`
  color: #777e91;
  text-align: center;
  font-size: 0.625rem;
  font-weight: 400;
`;

export default LoginModal;
