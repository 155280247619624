import React, { useContext, useState } from "react";
import { firebaseAuth, getRandomProfilePic } from "../helpers/authentication";
import styled from "styled-components";
import { TabItem } from "../components/TabItem";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { device, useBreakpoints } from "../lib/breakpoints";
import Hidden from "../components/Hidden";
import { Button, Spacer } from "../styles/CommonElements";
import { ReactSVG } from "react-svg";
import {
  DIALOG_NAME,
  useDialogSearchParam,
} from "../helpers/useDialogSearchParam";
import { EditModal, SettingsModal } from "../components/profile";

export default function Profile() {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { user, userInfo, isUserInfoLoading } = useContext(AuthContext);
  const [isEditParamSet, removeEditParam] = useDialogSearchParam(
    DIALOG_NAME.edit
  );
  const [isSettingsParamSet, removeSettingsParam] = useDialogSearchParam(
    DIALOG_NAME.settings
  );

  const [tab, setTab] = useState("ideas");

  const onLogout = async () => {
    try {
      await firebaseAuth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Sign Out Error", error);
    }
  };

  return (
    <Container>
      <InfoContainer>
        {user && (
          <Hidden tablet desktop>
            <LogoutButton onClick={onLogout}>Log out</LogoutButton>
          </Hidden>
        )}

        {user && (
          <StyledLink to={isMobile ? "/profile/edit" : `?${DIALOG_NAME.edit}=`}>
            <EditButton as="button">
              <Hidden mobile>
                <Pencil src="/icons/profile/pencil.svg" alt="" />
              </Hidden>
              Edit Profile
            </EditButton>
          </StyledLink>
        )}

        {user && (
          <DesktopProfileContent>
            <Spacer $marginTop="-8px" />
            <StyledLink to={`?${DIALOG_NAME.settings}=`}>
              <EditButton as="button">
                <Gear src="/icons/gear.svg" alt="" /> Settings
              </EditButton>
            </StyledLink>
            <Spacer $marginTop="4px" />
            <LogOut onClick={onLogout}>Log Out</LogOut>
          </DesktopProfileContent>
        )}
      </InfoContainer>

      <EditModal
        isOpen={isEditParamSet && !isMobile}
        onClose={() => removeEditParam()}
      />

      <SettingsModal
        isOpen={isSettingsParamSet && !isMobile}
        onClose={() => removeSettingsParam()}
      />
    </Container>
  );
}
const Container = styled.div`
  @media ${device.tablet}, ${device.desktop} {
    display: inline-flex;
    margin: auto;
  }

  @media (${device.tablet}) {
    width: 598px;
    gap: 12px;
    margin-top: 32px;
  }

  @media (${device.desktop}) {
    width: 900px;
    gap: 48px;
    margin-top: 40px;
  }
`;

const ContentContainer = styled.div`
  @media ${device.tablet}, ${device.desktop} {
    padding: 0px;
  }

  @media (${device.tablet}) {
    width: 354px;
  }

  @media (${device.desktop}) {
    width: 523px;
  }
`;

const DesktopProfileContent = styled.div`
  width: 100%;

  @media (${device.mobile}) {
    display: none;
  }
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #777e91;
  text-align: center;
  height: 35px;
  padding-left: 22px;
  padding-right: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  background: none;
  border-radius: 18px;
  cursor: pointer;
  font-family: var(--font-primary);
  margin-top: 18px;

  @media ${device.tablet}, ${device.desktop} {
    display: inline-flex;
    height: 40px;
    gap: 4px;
    border-radius: 100px;
    border: 2px solid #777e91;
    width: 100%;
    text-align: center;
    font-family: var(--font-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    padding: 20px 0px;
  }
`;

const Gear = styled.img`
  width: 18px;
  height: 18px;
  filter: brightness(0);
  margin-right: 4px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  position: relative;
  min-height: 230px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @media ${device.tablet}, ${device.desktop} {
    border-radius: 12px;
    border: 1px solid var(--border-light);
    padding: 24px;
    height: min-content;
  }

  @media (${device.tablet}) {
    width: 232px;
  }

  @media (${device.desktop}) {
    width: 308px;
  }
`;

const LogOut = styled(Button)`
  text-align: center;
  font-family: var(--font-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: var(--exitColor);
  background: transparent;

  &:hover {
    color: var(--exitColor);
  }
`;

const LogoutButton = styled.button`
  color: var(--exitColor);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.25s ease;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Pencil = styled(ReactSVG)`
  width: 18px;
  height: 18px;
  margin-right: 2px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 48px;
  padding: 0 20px;

  @media ${device.tablet}, ${device.desktop} {
    margin-top: 0;
    padding: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 16px;
  }

  @media ${device.desktop} {
    margin-bottom: 24px;
  }
`;

const StyledLink = styled(Link)`
  @media ${device.tablet}, ${device.desktop} {
    width: 100%;
  }
`;
