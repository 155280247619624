import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import _InputField from "../components/InputField";
import { useMutation } from "@tanstack/react-query";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { device, useBreakpoints } from "../lib/breakpoints";
import Button from "../components/Button";

const FIREBASE_AUTH_ERROR_CODE = "Firebase: Error (auth/invalid-credential).";
const FIREBASE_TIMEOUT_ERROR_CODE =
  "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).";

export default function ChangePassword({ onSuccess }) {
  const navigate = useNavigate();

  const { isTabletOrAbove } = useBreakpoints();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [revealCurrentPassword, setRevealCurrentPassword] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealConfirmedPassword, setRevealConfirmedPassword] = useState(false);

  let password1Ref = React.createRef();
  let password2Ref = React.createRef();
  let password3Ref = React.createRef();

  const editMutation = useMutation({
    mutationFn: async () => {
      const auth = getAuth();
      const credentials = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credentials);
      await updatePassword(auth.currentUser, newPassword);
    },
    onSuccess: async () => {
      setCurrentPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
      if (onSuccess) {
        onSuccess();
      } else {
        navigate("/profile");
      }
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (editMutation.status === "loading") return;
    editMutation.mutate();
  };

  const disableSubmit = useMemo(() => {
    return (
      currentPassword.length < 6 ||
      newPassword.length < 6 ||
      newPasswordConfirm.length < 6 ||
      newPassword !== newPasswordConfirm
    );
  }, [currentPassword, newPassword, newPasswordConfirm]);

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <InfoContainer>
      {!isTabletOrAbove && (
        <CancelContainer>
          <CancelButton onClick={onCancel}>
            <LeftCarat src="/icons/left-carat-lavender.svg" />
          </CancelButton>
        </CancelContainer>
      )}
      <FormContainer onSubmit={onSubmit}>
        <Title>Change Password</Title>
        <FieldContainer>
          <FieldLabel>Current Password</FieldLabel>
          <InputContainer>
            <InputField
              type={revealCurrentPassword ? "text" : "password"}
              value={currentPassword}
              maxLength={40}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Type your current password"
              ref={password1Ref}
            />
            <span
              className="flex justify-around items-center"
              onClick={() => {
                setRevealCurrentPassword(!revealCurrentPassword);
                password1Ref.current.focus();
              }}
            >
              {revealCurrentPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>New password</FieldLabel>
          <InputContainer>
            <InputField
              type={revealPassword ? "text" : "password"}
              value={newPassword}
              maxLength={40}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Must be greater than 6 characters"
              ref={password2Ref}
            />
            <span
              className="flex justify-around items-center"
              onClick={() => {
                setRevealPassword(!revealPassword);
                password2Ref.current.focus();
              }}
            >
              {revealPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Confirm new password</FieldLabel>
          <InputContainer>
            <InputField
              type={revealConfirmedPassword ? "text" : "password"}
              value={newPasswordConfirm}
              maxLength={40}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              placeholder="Type your new password again"
              ref={password3Ref}
            />
            <span
              className="flex justify-around items-center"
              onClick={() => {
                setRevealConfirmedPassword(!revealConfirmedPassword);
                password3Ref.current.focus();
              }}
            >
              {revealConfirmedPassword ? (
                <HidePasswordIcon src="/images/general/password-hide.svg" />
              ) : (
                <RevealPasswordIcon src="/images/general/password-reveal.svg" />
              )}
            </span>
          </InputContainer>
        </FieldContainer>
        {editMutation.error && (
          <ErrorText>
            {editMutation.error.message !== FIREBASE_AUTH_ERROR_CODE &&
              editMutation.error.message !== FIREBASE_TIMEOUT_ERROR_CODE &&
              editMutation.error.message}

            {editMutation.error.message === FIREBASE_AUTH_ERROR_CODE &&
              "Incorrect password"}

            {editMutation.error.message === FIREBASE_TIMEOUT_ERROR_CODE &&
              "Please try again later"}
          </ErrorText>
        )}
        <SubmitButton disabled={disableSubmit}>
          {editMutation.status === "pending" ? (
            <Spinner alt="" src="/icons/loading-spheres.svg" />
          ) : (
            "Save"
          )}
        </SubmitButton>
      </FormContainer>
    </InfoContainer>
  );
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding-top: 0px;

  @media ${device.mobile} {
    padding-top: 20px;
  }
`;

const CancelButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #ab9bea;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;

const CancelContainer = styled.div`
  display: block;
  align-items: left;
  justify-content: flex-start;
  width: 90%;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 160%;
  color: var(--color-red);
  margin-bottom: 0px;
  margin-top: 14px;
  text-align: left;

  @media ${device.tablet}, ${device.desktop} {
    margin-top: 0px;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: black;
  padding-left: 0px;
  text-align: left;
  margin-bottom: 6px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;

  @media ${device.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const HidePasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
`;

const InputField = styled(_InputField)`
  &::placeholder {
    font-style: normal;
  }
`;

const LeftCarat = styled.img`
  height: 24px;
  margin: 6px 2px 6px auto;
`;

const Title = styled.h1`
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  padding-left: 0px;
  font-weight: 600;
  text-align: center;
  display: block;
  margin-inline: auto;
  width: fit-content;

  @media ${device.mobile} {
    margin-top: 20px;
  }
`;

const RevealPasswordIcon = styled.img`
  right: 14px !important;
  top: 29%;
  height: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
`;

const Spinner = styled.img`
  width: 25px;
  height: 25px;
  animation: rotation 2s infinite linear;
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 99px;
  cursor: pointer;
  margin: auto;

  &:disabled {
    pointer-events: none;
    user-select: none;
  }
`;
