import { ConfigProvider } from "antd";
import { darkTheme } from "@ant-design/compatible";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { NavBar } from "../components/general";
import { NavFooter } from "../components";
import { AppLayout, ContentLayout } from "./Layout";
import OffscreenTrigger from "../components/OffscreenTrigger";
import AuthProvider from "../context/AuthProvider";
import ScrollOffscreenProvider from "../context/ScrollOffscreenProvider";
import BackLocationsProvider from "../context/BackLocationsProvider";
import { useEffect } from "react";
import ContentGate from "../components/ContentGate";
import SignupModal from "../components/login/SignupModal";
import { ErrorBoundary } from "react-error-boundary";
import RouterErrorBoundary from "./RouterErrorBoundary";

// https://ant.design/docs/react/migrate-less-variables#how-to-use-component-token
export const antdDarkTheme = {
  ...darkTheme,
  token: {
    // Blurple
    colorPrimary: "#505dff",
  },
  components: {
    Layout: {
      bodyBg: "#212121",
    },
    Popover: {
      colorBgElevated: "#212121",
    },
    Notification: {
      colorBgElevated: "transparent",
      boxShadow: "none",
    },
    Table: {
      headerSortHoverBg: "#424242",
    },
    Skeleton: {
      gradientFromColor: "#424242",
    },
  },
};

export default function Presto() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return (
    <ConfigProvider theme={antdDarkTheme}>
      <AuthProvider>
        <BackLocationsProvider>
          <ScrollOffscreenProvider>
            <AppLayout>
              <Container>
                <NavBar />
                <ContentLayout>
                  <ErrorBoundary FallbackComponent={RouterErrorBoundary}>
                    <Outlet />
                  </ErrorBoundary>
                </ContentLayout>
                <NavFooter />
              </Container>
              <OffscreenTrigger />
            </AppLayout>
            <ContentGate />
            <SignupModal />
          </ScrollOffscreenProvider>
        </BackLocationsProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

const Container = styled.div`
  .ant-menu-item {
    margin-bottom: 14px !important;
  }
`;
