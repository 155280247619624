"use client";
// ----- Components Import -----
import { styled } from "styled-components";

// ----- Assets Import -----
import { device, useBreakpoints } from "../../lib/breakpoints";
import { Col, Row } from "antd";
import BookDemoButton from "../../components/general/BookDemoButton";
import { Spacer } from "../../styles/CommonElements";
import { Link } from "react-router-dom";

const bannerImage = "/images/home/ready-to-start.jpg";

export default function HomeCTA() {
  const { isTabletOrAbove } = useBreakpoints();

  return (
    <Container>
      {isTabletOrAbove ? (
        <StyledRow>
          <LeftCol span={10}>
            <Title>
              Ready to{" "}
              <Highlight $color="var(--color-brand-primary)">
                transform
              </Highlight>{" "}
              your customer experience?
            </Title>
            <Spacer $marginTop="1rem" />
            <BookDemoButton />
          </LeftCol>
          <RightCol span={14}>
            <Image src={bannerImage} alt="" />
          </RightCol>
        </StyledRow>
      ) : (
        <StyledRow>
          <LeftCol span={24}>
            <Title>
              Ready to{" "}
              <Highlight $color="var(--color-brand-primary)">
                transform
              </Highlight>{" "}
              your customer experience?
            </Title>
            <Spacer $marginTop="1rem" />
            <BookDemoButton />
          </LeftCol>
        </StyledRow>
      )}
    </Container>
  );
}

// ----- Styles -----
const Container = styled.div`
  width: 100%;
  height: 500px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRow = styled(Row)`
  width: 100%;
  height: 100%;
  padding: 0 10%;
  display: flex;
  align-items: center;
`;

const LeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align to the top */
`;

const RightCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

const Highlight = styled.span`
  color: ${(props) => props.$color || "inherit"};
`;

const Image = styled.img`
  height: 100%;
  object-fit: contain;
  vertical-align: bottom;
`;

const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: var(--color-brand-complimentary);
  line-height: 1.25;
  text-align: left;

  @media ${device.tablet} {
    font-size: 48px;
  }

  @media ${device.mobile} {
    font-size: 32px;
  }
`;
