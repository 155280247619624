import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { device } from "../../lib/breakpoints";

const Card = ({ feature, overlayOpacity = 0 }) => {
  return (
    <CardContainer>
      <Row>
        <Col span={14}>
          <CardContent>
            <CardTitle>{feature.title}</CardTitle>
            <CardText>{feature.desc}</CardText>
          </CardContent>
        </Col>
        <Col span={10}>
          <ImageWrapper>
            <CardImage src={feature.icon} alt={feature.title} />
            <ImageOverlay opacity={overlayOpacity} />
          </ImageWrapper>
        </Col>
      </Row>
    </CardContainer>
  );
};

export default Card;

// ----- Styles -----
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media ${device.mobile} {
    height: auto;
    min-height: 280px;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  height: 100%;
  padding: 24px 20px;
  text-align: left;
  padding-left: 10%;

  @media ${device.mobile} {
    margin-left: 0;
    text-align: left;
    padding: 18px 14px;
    padding-left: 20%;

  }

  @media ${device.tablet} {
    padding-left: 15%;
    padding-right: 0;
  }
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  color: var(--color-brand-primary);

  @media ${device.mobile} {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const CardText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
  padding: 0;

  @media ${device.mobile} {
    font-size: 10px;
    line-height: 1.3;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 4px 0 0;
  }

  @media ${device.tablet} {
    font-size: 12px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardImage = styled.img`
  width: 50%;
  height: auto;
  object-fit: contain;
  object-position: center;

  @media ${device.mobile} {
    width: 80%;
    padding-right: 20%;
    
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: ${(props) => props.opacity || 0};
  transition: opacity 0.3s ease;
`;
