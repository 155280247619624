import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { device } from "../../lib/breakpoints";

const StyledSlider = ({ settings, children }) => {
  return <SliderWrapper {...settings}>{children}</SliderWrapper>;
};

export default StyledSlider;

// ----- Styles -----
const SliderWrapper = styled(Slider)`
  .slick-list {
    overflow: hidden;
  }

  .slick-slide {
    padding: 0 40px; /* Increase padding for larger width */
    width: calc(100% / 3 - 80px); /* Adjust width to account for padding */

    @media (${device.mobile}) {
      padding: 0;
    }
  }

  .slick-center {
    transform: scale(1.1);
  }

  .slick-dots {
    bottom: -40px;

    li {
      margin: 0; /* Add margin to separate dots */
    }

    li button:before {
      font-size: 12px;
      color: var(--color-brand-primary);
    }

    li.slick-active button:before {
      color: var(--color-brand-primary);
    }
  }
`;
