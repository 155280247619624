export const submissionKeys = {
  all: ["submissions"],
  submission: (submissionId) => [...submissionKeys.all, submissionId],
  gallery: () => [...submissionKeys.all, "gallery"],
  gallery_count: (questId) => [...submissionKeys.gallery(), questId, "count"],
  gallery_results: (questId) => [
    ...submissionKeys.gallery(),
    questId,
    "results",
  ],
  quest: () => [...submissionKeys.all, "quest"],
  quest_submissions: (questId) => [...submissionKeys.quest(), questId],
  quest_count: (questId) => [...submissionKeys.quest(), questId, "count"],
  user_submission: (questId, userId) => [
    ...submissionKeys.quest_submissions(questId),
    "user",
    userId,
  ],
};

export const predictionKeys = {
  all: ["predictions"],
  user: (userId) => [...predictionKeys.all, "user", userId],
  patient: (patientId) => [...predictionKeys.all, "patient", patientId],
};

export const patientKeys = {
  all: ["patients"],
  user: (userId) => [...patientKeys.all, "user", userId],
  patient: (patientId) => [...patientKeys.all, "patient", patientId],
};

export const userKeys = {
  all: ["users"],
  user: (userId) => [...userKeys.all, userId],
  user_banned: (userId) => [...userKeys.user(userId), "banned"],
  profile_submissions: (userId) => [
    ...userKeys.user(userId),
    "profile-submissions",
  ],
  profile_liked: (userId) => [...userKeys.user(userId), "profile-liked"],
};

export const commentKeys = {
  all: ["comments"],
  count: () => [...commentKeys.all, "count"],
  // This wraps two very different queryFns at the moment, but it works because a subectId is unique to a content type (quest, ep)
  subject: (subjectId) => [...commentKeys.all, "subject", subjectId],
};

export const bookmarkKeys = {
  all: ["bookmarks"],
  storyworld: (storyworldId) => [...bookmarkKeys.all, storyworldId],
};

export const questKeys = {
  all: ["quests"],
  // * Should probably be used with the queries in Storyworlds.jsx and QuestsAll.jsx, but they fetch the storyworldId inside the queryFn
  // from_storyworld_single: (storyworldId) => [
  //   ...questKeys.all,
  //   "storyworld",
  //   storyworldId,
  //   "single",
  // ],
  from_storyworld: (storyworldId) => [
    ...questKeys.all,
    "storyworld",
    storyworldId,
  ],
  quest: (questId) => [...questKeys.all, questId],
  winners: (questId) => [...questKeys.quest(questId), "winners"],
};

export const storyworldKeys = {
  all: ["storyworlds"],
  storyworld: (storyworldId) => [...storyworldKeys.all, storyworldId],
  views: (storyworldId) => [
    ...storyworldKeys.storyworld(storyworldId),
    "views",
  ],
  // TODO: consider adding userId param
  user_storyworlds: () => [...storyworldKeys.all, "user"],
  published: () => [...storyworldKeys.all, "published"],
  by_creator: (userId) => [...storyworldKeys.all, "creator", userId],
  with_quests_no_catalog: () => [...storyworldKeys.all, "quests_no_catalog"],
};

export const promoKeys = {
  all: ["promos"],
  created: () => [...promoKeys.all, "created"],
  published: () => [...promoKeys.all, "published"],
  promo: (promoId) => [...promoKeys.all, promoId],
};

export const voteKeys = {
  all: ["votes"],
  quest: () => [...submissionKeys.all, "quest"],
  quest_user_vote: (questId, userId) => [
    ...voteKeys.quest(),
    questId,
    "user",
    userId,
    "vote",
  ],
  quest_user_count: (questId, userId) => [
    ...voteKeys.quest(),
    questId,
    "user",
    userId,
    "count",
  ],
  poll: () => [...submissionKeys.all, "poll"],
  poll_count: (pollId) => [...voteKeys.poll(), pollId, "count"],
  poll_user_count: (pollId, userId) => [
    ...voteKeys.poll(),
    pollId,
    "user",
    userId,
    "count",
  ],
};

export const pollKeys = {
  all: ["polls"],
  completed: () => [...pollKeys.all, "completed"],
  from_storyworld: (storyworldId) => [
    ...pollKeys.all,
    "storyworld",
    storyworldId,
  ],
};

export const episodeKeys = {
  all: ["episodes"],
  episode: (episodeId) => [...episodeKeys.all, episodeId],
  from_storyworld: (storyworldId) => [
    ...episodeKeys.all,
    "storyworld",
    storyworldId,
  ],
  from_storyworld_count: (storyworldId) => [
    ...episodeKeys.from_storyworld(storyworldId),
    "count",
  ],
  // TODO: remove this key when the query is updated. Shouldnt be a combo query.
  from_storyworld_with_bookmark: (storyworldId) => [
    ...episodeKeys.all,
    "storyworld-bookmark",
    storyworldId,
  ],
};

export const creatorPollKeys = {
  all: ["creatorPolls"],
  from_storyworld: (storyworldId) => [
    ...creatorPollKeys.all,
    "storyworld",
    storyworldId,
  ],
};

export const creatorPollVoteKeys = {
  all: ["creatorPollVotes"],
  poll: (creatorPollId) => [...creatorPollVoteKeys.all, creatorPollId],
  vote_count: (creatorPollId) => [
    ...creatorPollVoteKeys.poll(creatorPollId),
    "count",
  ],
};

export const likeKeys = {
  all: ["likes"],
  item: (itemId) => [...likeKeys.all, "item", itemId],
};

export const alertKeys = {
  all: ["alerts"],
  user: (userId) => [...alertKeys.all, "user", userId],
};

export const alertConfigKeys = {
  all: ["alertConfigs"],
  user: (userId) => [...alertConfigKeys.all, userId],
};
