import { useContext, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import Button from "../../Button";
import { OPTION_TYPES, SettingsModalHeader } from "./SettingsModal";
import { FormError, InputHeader, Spacer } from "../../../styles/CommonElements";
import InputField from "../../InputField";
import { AuthContext } from "../../../context/AuthProvider";
import { useForm } from "react-hook-form";
import { usernameExists } from "../../../helpers/firebase";
import {
  MIN_USERNAME_LENGTH,
  ERROR_PROFANITY,
} from "../../../helpers/constants";
import {
  containsProfanity,
  removeIllegalCharacters,
} from "../../../helpers/general";
import userModel from "../../../lib/firebase/userModel";
import { userKeys } from "../../../lib/queryKeys";
import { message } from "antd";
import { DevTool } from "@hookform/devtools";

const ManageAccount = ({ setType }) => {
  const { user, userInfo } = useContext(AuthContext);
  const [doesUsernameExist, setUsernameExists] = useState(false);

  const queryClient = useQueryClient();

  const { mutate: editProfile, isPending } = useMutation({
    mutationFn: async ({ username }) => {
      const payload = {
        ...userInfo,
        username,
      };

      return userModel.update({ id: user.uid, data: payload });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.user(user.id) });
      message.success("Profile updated successfully");
      reset();
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
    formState: { isValid, isDirty, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      username: userInfo?.username,
    },
  });

  // Watching the input fields value and running a debounce function to check if the username exists
  // Hook form can handle asynchronous validation, but it doesn't have a built-in debounce function
  const [, cancelDebounce] = useDebounce(
    async () => {
      const username = watch("username");

      if (!username || username.length < MIN_USERNAME_LENGTH) {
        return;
      }

      if (username === userInfo?.username) {
        setUsernameExists(false);
        return;
      }

      const result = await usernameExists(username);
      setUsernameExists(result);
    },
    500,
    [watch("username")]
  );

  useEffect(() => {
    return () => {
      cancelDebounce();
    };
  }, [cancelDebounce]);

  return (
    <>
      <SettingsModalHeader>Manage Account</SettingsModalHeader>
      <Spacer $marginTop="0.75rem" />
      <form onSubmit={handleSubmit(editProfile)}>
        <InputHeader htmlFor="username">Username</InputHeader>
        <Spacer $marginTop="0.25rem" />
        <InputField
          {...register("username", {
            required: "Please enter a username",
            minLength: {
              value: MIN_USERNAME_LENGTH,
              message: `Username must be at least ${MIN_USERNAME_LENGTH} characters`,
            },
            validate: {
              profane: (value) => !containsProfanity(value) || ERROR_PROFANITY,
              exists: () =>
                !doesUsernameExist || "This username is already taken",
            },
            onChange: (e) =>
              setValue("username", removeIllegalCharacters(e.target.value)),
          })}
          id="username"
          maxLength={40}
          type="text"
        />
        {errors?.username && watch("username") !== userInfo?.username && (
          <FormError>{errors?.username?.message}</FormError>
        )}
        <Spacer $marginTop="1rem" />
        <InputHeader htmlFor="email">Email</InputHeader>
        <Spacer $marginTop="0.25rem" />
        <InputField id="email" value={user?.email} disabled />
        <Spacer $marginTop="1rem" />
        <InputHeader htmlFor="password">Password</InputHeader>
        <Spacer $marginTop="0.25rem" />
        <InputField id="password" value="********" disabled />
        <ChangePasswordWrapper>
          <Button
            variant="text"
            size="small"
            width="unset"
            onClick={() => setType(OPTION_TYPES.PASSWORD)}
            style={{ paddingRight: "0px" }}
          >
            Change Password
          </Button>
        </ChangePasswordWrapper>
        <Spacer $marginTop="0.75rem" />
        <Button
          type="submit"
          size="large"
          isLoading={isPending}
          disabled={!isValid || !isDirty || isPending}
        >
          Save
        </Button>
      </form>
      <Spacer $marginTop="1.5rem" />
      {/*
      <DeleteButtonWrapper>
        <Button
          variant="text"
          size="small"
          width="unset"
          onClick={() => setType(OPTION_TYPES.DELETE)}
        >
          <img src="/icons/social/delete.svg" alt="" />
          Delete Account
        </Button>
      </DeleteButtonWrapper>
      */}
      <DevTool control={control} />
    </>
  );
};

export default ManageAccount;

const ChangePasswordWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
`;

const DeleteButtonWrapper = styled.div`
  color: var(--color-red);
  display: flex;
  justify-content: center;
`;
